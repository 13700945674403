.modal-open {
  overflow: hidden;

  .account-nav-bar {
    z-index: 1;
  }
}
.no-scroll {
  overflow: hidden;
}
.mdc-dialog__surface {
  border-radius: 19px!important;
  overflow: hidden!important;
}
.cdk-global-scrollblock {
  position: unset !important;
}

.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 42%) !important;
}
.cdk-overlay-pane {
  max-width: 100% !important;
}

.mat-dialog-container {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: 19px !important;
}

.modal {
  z-index: 10000;
  width: 100% !important;
  height: 100%;
  background-color: transparent;
  opacity: 1;
  transition:
    opacity,
    visibility 0.3s 0.12s;

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (#{$larger-than-desktop}) {
      width: 100%;
    }
  }

  .modal-wrapper,
  .model-container {
    z-index: 9999;
    background-color: #fff;
    border-radius: 19px;
    max-height: 90vh;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    max-width: 700px;

    @media (max-width: $phablet-min-width-minus) {
      max-width: 575px;
    }
  }

  .modal-header,
  .modal-content {
    text-align: center;

    fieldset {
      text-align: center;
      margin-bottom: 0;
    }
  }

  .modal-content {
    padding-bottom: 10px;
    padding: .5em 1em;
    font-size: 18px;
    line-height: 28px;

    @media (max-width: $phablet-min-width-minus) {
      font-size: 16px;
      line-height: 24px;
    }

    .row {
      margin-bottom: 10px;
    }

    .twelve {
      width: 93%;
      margin: 0 auto;
      float: none;
    }

    &>* {
      display: flex;
      //align-items: center;
      justify-content: flex-start;

      @media (#{$larger-than-phablet}) {
        padding: 0.5em 4em;
      }
    }
  }

  .modal-body {
    max-height: calc(90vh - 65px);
    overflow-y: auto;
  }

  .modal-header {
    position: relative;
    background-color: #fff;
    text-align: center;
  }

  .modal-close {
    position: absolute;
    top: 10px;
    right: 0px;
    padding: 3px;
    color: white;
    background: $primary-color;
    border: 0;
    cursor: pointer;
    border-radius: 50%;
    height: 28px;
    width: 28px;

    @media (max-width: 641.9px) {
      right: 10px;
    }

    @media (max-width: $phablet-min-width-minus) {
      height: 24px;
      width: 24px;
    }

    i {
      font-size: rem-calc(20px);

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(16px);
      }
    }
  }

  .modal-heading {
    margin-bottom: 10px;
    margin-top: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (max-width: $phablet-min-width-minus) {
      margin-bottom: 16px;
    }
  }
}

.acp-limit {
  width: 70% !important;

  @media (max-width: $phablet-min-width) {
    width: 100% !important;
  }
}

.confirm-password-modal {
  @include modal-layout;

  &.model-container {
    .modal-header {
      .modal-heading {
        margin-bottom: 16px;
      }
    }

    .modal-body {
      .modal-content {
        .message {
          display: block;
        }
      }

      fieldset {
        position: relative;
        margin: 0;
        max-width: 238px;
        padding: 0;
        width: 100%;

        .outlined-input {
          height: 38px;
          margin: 20px auto 0;
          width: 100%;

          input{
            &:not(:focus) {
              &:placeholder-shown {
                +span {
                  font-size: rem-calc(14px);
                }
              }
            }
          }
        }
      }

      .eye-icon {
        position: absolute;
        right: 8px;
        top: calc(50% - 1px);
      }
    }

    .modal-actions {
      margin-top: 20px !important;
    }

    .validation-message {
      color: $error;
      line-height: 20px;
    }
  }
}

.duplicate-result-modal {
  width: 100%;
  max-width: 700px;

  &.error {
    .modal-header {
      .modal-heading {
        color: #d22119;
      }
    }
  }

  .modal-header {
    padding: 32px 20px;
    padding-bottom: 0px;

    .modal-heading {
      width: 100%;
      margin-bottom: 18px;
      font-size: rem-calc(32px);
      font-weight: bold;
      line-height: 40px;
      text-align: center;
    }
  }

  .modal-body {
    .modal-content {
      max-width: 600px;
      padding: 0em 1em;
      display: flex;
      flex-direction: column;

      .modal-actions {
        padding: 0;
        margin: 20px auto;

        button {
          width: 111px;
          padding: 8px;
        }
      }
    }
  }
}

.update-email-verification {
  @include modal-layout;

  &.model-container {
    .modal-body {
      .modal-content {
        padding: 0 16px;
      }
    }

    .middle-info {
      margin-top: 12px;
      margin-bottom: 8px;
    }

    @media (max-width: $phablet-min-width-minus) {
      .middle-info {
        margin-top: 8px;
        margin-bottom: 4px;
      }  
    }
  }
}

.autoPay-help-modal {
  width: 100%;
  max-width: 960px;
  border: 1px solid $input-border;

  .modal-header {
    .modal-heading {
      width: 100%;
      text-align: left;
      margin: 15px 0 0;

      @media (#{$larger-than-phablet}) {
        font-size: rem-calc(25px);
        padding: 0 30px;
        font-family: $secondary-font-family-bold;
        font-weight: bold;
        line-height: 30px;
      }

      @media (max-width: 1024px) {
        padding: 0 55px;
      }

      @media (max-width: 640px) {
        padding: 0 20px;
      }

      font-size: rem-calc(31px);
      width: 100%;
      max-width: 700px;
      line-height: 41px;
    }
  }

  .modal-content {
    text-align: left;
    padding: 0 1em 20px;
    font-size: rem-calc(16px);

    .description-content {
      margin-bottom: 20px;
    }

    .payment-sub {
      margin-bottom: 20px;
    }

    @media (#{$larger-than-phablet}) {
      font-size: rem-calc(17px);
    }
  }

  .modal-actions {
    margin: 0 0 1em;

    .button {
      padding: 10px 50px;
      width: 220px;
      margin-top: 0;
    }

    @media (max-width: 640px) {
      display: block;

      .button {
        display: block;
        margin: 0 auto;
        width: 213px;
        font-size: rem-calc(20px);
      }
    }
  }

  .modal-close {
    background: #fff;
  }

  .modal-content>* {
    padding: 0.5em 1em;

    @media (#{$larger-than-phablet}) {
      padding: 0 50px 20px;
    }
  }
}

.wifi-calling-modal {
  max-width: 375px;
  border: 2px solid $primary-color;
  border-radius: 11px;
  width: 100%;

  .modal-header {
    padding: 30px 20px 10px;

    .modal-heading {
      font-size: rem-calc(25px);
      font-weight: 500;
      line-height: 33px;
      width: 100%;
      max-width: 100%;
      padding: 0 1em;
    }
  }

  .modal-body {
    max-height: calc(80vh - 65px);
    overflow-x: hidden;

    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;

      .form-header {
        padding: 0 10px;
        font-size: rem-calc(15px);
        line-height: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        font-family: $base-font-family-bold;
      }

      .form-description {
        padding: 0 10px;
        font-size: rem-calc(14px);
        line-height: 22px;
        font-family: $base-font-family;
        text-align: left;
      }

      .form-section {
        padding: 10px;

        &:first-child {
          padding: 0px;
        }

        fieldset {
          text-align: left;
          width: 100%;
          margin-bottom: 15px;

          input {
            width: 100%;
            height: 45px;
          }

          label {
            margin-bottom: 9px;
          }

          .icon-search {
            display: none;
          }
        }

        .address-lookup {
          padding: 0;
        }

        .four {
          padding: 0;
        }
      }

      .checks {
        padding: 0 20px 0 10px;
        text-align: left;

        .checkbox {
          display: flex;
          align-items: baseline;
          position: relative;

          .checkbox-label {
            padding-left: 30px;
            font-size: rem-calc(14px);
            cursor: pointer;

            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              margin: 4px;
              width: 20px;
              height: 20px;
              transition: transform 0.28s ease;
              border-radius: 5px;
              border: 1px solid $input-border;
            }

            &:after {
              content: "";
              display: block;
              width: 10px;
              height: 5px;
              border-bottom: 2px solid $primary-color;
              border-left: 2px solid $primary-color;
              transform: rotate(-45deg) scale(0);
              transition: transform ease 0.25s;
              position: absolute;
              top: 11px;
              left: 10px;
            }
          }

          input[type="checkbox"] {
            width: auto;
            opacity: 0.00000001;
            position: absolute;
            left: 0;
            margin-left: -20px;

            &:checked~label {
              &:before {
                border: 1px solid $input-border;
              }

              &:after {
                transform: rotate(-45deg) scale(1);
              }
            }

            &:focus+label::before {
              outline: 0;
            }
          }
        }

        .warning {
          padding-left: 30px;

          .note {
            color: $secondary-color;
            font-style: italic;
            font-size: rem-calc(14px);
            line-height: 22px;
          }
        }
      }

      .modal-actions {
        margin: 0 auto 30px;

        .button {
          width: 135px;
          font-size: rem-calc(20px);
          padding: 8px;
        }
      }
    }

    .modal-content>* {
      padding: 0;
    }
  }
}

.wifi-result-modal {
  width: 100%;
  max-width: 302px;
  border: 1px solid $primary-color;
  border-radius: 11px;

  .modal-header {
    padding: 30px 20px 10px;

    .modal-close {
      background-color: transparent;
    }

    .modal-heading {
      font-size: rem-calc(25px);
      font-weight: 500;
      line-height: 33px;
      width: 100%;
    }
  }

  .modal-body {
    .modal-content {
      .row {
        padding: 0 26px;
        font-size: rem-calc(14px);
      }
    }
  }
}

.edit-wifi-modal {
  .modal-body {
    .modal-content {
      .twelve {
        display: none;
      }
    }
  }
}

.disable-wifi-modal {
  max-width: 302px;
  width: 100%;
  border: 1px solid $primary-color;
  border-radius: 11px;

  .modal-header {
    padding:16px;

    .modal-close {
      display: none;
    }

    .modal-heading {
      width: 100%;
    }
  }

  .modal-body {
    .modal-content {
      padding: 0 0 33px;

      .modal-actions {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 14px;
      }
    }
  }
}

.referral-promo-modal {
  max-width: 700px;
  width: 100%;
  border-radius: 0;
  max-height: 562px;
  height: 100%;
  padding: 0;

  @media (max-width: 739.9px) {
    background: none;
  }

  .modal-header {
    padding: 0;
    z-index: 999;

    .modal-close {
      background-color: $primary-color;
      width: 44px;
      height: 44px;
      background-image: url(/assets/icon/modal-close.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15.78px;

      .icon-close {
        z-index: 999;
      }

      .icon-close:before {
        content: "";
      }
    }
  }

  .modal-body {
    overflow: hidden;
    height: 100%;
    max-height: 531px;

    .modal-content {
      padding: 0;

      .content {
        padding: 0;
        display: flex;

        .image-section {
          display: flex;
          flex-basis: 50%;
          overflow: hidden;

          img {
            max-width: 370px;
            height: 531px;
            z-index: 0;
            object-fit: cover;

            @media (max-width: $phablet-min-width-minus) {
              height: 300px;
            }
          }
        }

        .info-section {
          padding: 0px 40px 0px;
          background-color: #f4f4f4;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 531px;

          @media (max-width: 739.9px) {
            height: 100%;
          }

          .title {
            font-size: rem-calc(30px);
            line-height: 40px;
            margin-bottom: 30px;
            margin-top: 30px;
            max-width: 250px;
          }

          .description {
            font-size: rem-calc(18px);
            line-height: 24px;
            margin-bottom: 33px;
            max-width: 272px;
          }

          .button {
            width: 225px;
            font-size: rem-calc(18px);
            height: 45px;
            margin-bottom: 25px;
          }

          .skip {
            color: $primary-color;
            cursor: pointer;
            font-family: $secondary-font-family;
            font-weight: 500;
            font-size: rem-calc(18px);
            line-height: 24px;
            margin-bottom: 60px;
          }

          .terms {
            color: $primary-color;
            cursor: pointer;
            font-weight: bold;
            font-size: rem-calc(13px);
            line-height: 24px;
            text-decoration: underline;
          }
        }
      }
    }

    @media (max-width: 739.9px) {
      max-height: 562px;
      height: 562px;
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;
      top: 0px;
      right: 0;
      left: 0;
      max-width: 300px;
      margin: 0 auto;

      .modal-content {
        .content {
          flex-direction: column;

          .image-section {
            img {
              max-width: 300px;
              z-index: 0;
              height: 300px;
              width: 300px;
              object-position: top;
            }
          }

          .info-section {
            padding: 16px 12px;

            .title {
              font-size: rem-calc(25px);
              margin-bottom: 21px;
              margin-top: 5px;
              line-height: 33px;
            }

            .description {
              margin-bottom: 18px;
            }

            .button {
              margin-bottom: 25px;
            }

            .skip {
              margin-bottom: 19px;
            }
          }
        }
      }
    }
  }
}

.activation-info-modal {
  width: 100%;
  max-width: 769px;
  border: 1px solid $input-border;
  border-radius: 11px;

  .modal-header {
    .modal-heading {
      font-size: rem-calc(25px);
      font-family: $secondary-font-family-bold;
      line-height: 33px;
      padding-top: 20px;

      @media (max-width: 633.9px) {
        width: 262px;
        line-height: 1.32;
      }
    }

    .modal-close {
      background: none;
    }

    @media (max-width: 833.9px) {
      .modal-heading {
        line-height: 41px;
        width: 262px;
      }
    }
  }

  .modal-body {
    overflow-x: hidden;
  }

  .button {
    background-color: $primary-color;
    width: 154px;
    padding: 7px;
    font-size: rem-calc(20px);
    line-height: 27px;
    margin-top: -41px;

    @media (max-width: 833.9px) {
      width: 165px;
    }

    @media (max-width: 633.9px) {
      width: 145px;
    }
  }

  .modal-content {
    padding: 0 0px;

    .row {
      display: block;

      .text-content-holder {
        width: 609px;
        max-width: 609px;
        margin-bottom: 30px;
        font-size: rem-calc(18px);

        @media (max-width: 833.9px) {
          width: 404px;
        }

        @media (max-width: 633.9px) {
          width: 296px;
          padding: 0 16px;
          font-size: rem-calc(17px);
        }
      }

      .image-container {
        margin-bottom: 30px;

        @media (max-width: 633.9px) {
          margin-bottom: 38px;
        }

        .activation-image {
          width: 288.9px;
          height: 181.9px;

          @media (max-width: 633.9px) {
            padding: 0 28.4px;
            height: 155px;
          }
        }
      }
    }
  }
}

.manage-payment-modal {
  @include modal-layout;
  &.model-container {
    max-height: 95vh!important;
    width: 600px;
    
    .modal-heading {
      padding: 8px 48px 0!important;
    }
    .modal-body {
      max-height: unset;
      padding: 0;
      overflow: hidden;

      .top-note {
        margin-bottom: 20px;
        font-weight: 400;
      }

      .modal-content {
        padding: 0 24px;
        margin: 0;
        
        .cards-holder {
          border-top: 2px solid $grayscale-Shade-50;
          border-bottom: 2px solid $grayscale-Shade-50;
          overflow: auto;
          max-height: calc(95vh - 325px);;
          padding: 16px 12px;
          width: 100%;

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: $sg-white;
            border: 1px solid $white-grey;
            border-radius: 4px;
          }
  
          &::-webkit-scrollbar-thumb {
            background: $input-border;
            border-radius: 4px;
          }
          
          @media (max-width: $phablet-min-width-minus) {
            max-height: calc(95vh - 249px);
            padding: 8px 4px;
          }

          @media (max-width: 583px){
            max-height: calc(95vh - 275px);
          }

          @media (max-width: 503px){
            max-height: calc(95vh - 300px);
          }

          @media (max-width: 360px){
            max-height: calc(95vh - 319px);
          }

          @media screen and (min-height: 804px) {
            max-height: calc(764px - 325px);

            @media (max-width: $phablet-min-width-minus) {
              max-height: calc(764px - 249px);
            }
    
            @media (max-width: 583px){
              max-height: calc(764px - 275px);
            }
    
            @media (max-width: 503px){
              max-height: calc(764px - 300px);
            }

            @media (max-width: 360px){
              max-height: calc(764px - 319px);
            }
          }
        }

        @media (max-width: $phablet-min-width-minus) {
          padding: 0 16px;
        }

        .no-payments {
          padding: 0;
          margin: 0;

          .desc {
            margin-bottom: 12px;

            @media (max-width: $phablet-min-width-minus) {
              margin-bottom: 8px;
            }
          }

          .modal-actions {
            .button {
              width: unset;
            }
          }
        }

        .modal-actions {
          position: sticky;
          bottom: 0;
          flex-direction: column;
          gap: 0;
          background: $sg-white;

          &:has(.validation-message) {
            margin-top: 12px;
          }

          .modal-hint {
            margin: 0 auto 24px;
            a{
              font-weight: 700;
            }
          }

          .validation-message {
            margin: 0 0 24px;
          }      
    
          .button {
            width: 145px;
          }
    
          @media (max-width: $phablet-min-width-minus) {
            display: block;

            .validation-message {
              text-align: center;
              margin: 0 0 20px;
            }

            &:has(.validation-message) {
              margin-top: 8px;
            }

            .modal-hint {
              display: block;
              margin-bottom: 20px;
            }
    
            .button {
              width: 136px;
            }
          }
        }
      }
    }
  }

  .account-address-details-container {
    display: flex!important;
    align-items: flex-start;
    input[type=radio] {
      margin-top: 0;
    }
    &.highlight-card {
      width: 100%;
      text-align: left;
      padding: 16px;
      gap: 12px;
      background-color: $primary-color-hover;

      .details {
        text-align: left;
        padding: 0;
        .top {
          margin-bottom: 4px;
        }

        .expiry {
          color: $black
        }
      }
    }
    &.red-border {
      border: 0.5px solid $red;
    }

    @media (max-width: $phablet-min-width-minus) {
      &.highlight-card {
        padding: 12px;
      }
    }
  }
}

.custom-modal-view-updates {
  @media (min-width: 1025px) {
    max-width: 750px;
  }

  @media (min-width: 640px) and (max-width: 1024.9px) {
    max-width: 610px;
  }

  height: 100%;

  .modal-header {
    .modal-heading {
      display: none;
    }

    .modal-close {
      background: unset;
    }
  }

  .modal-body {
    .modal-content {
      padding-top: 0;
      text-align: left;

      .row {
        @media (max-width: $phablet-min-width-minus) {
          padding: 20px 6px;
        }

        h1 {
          @media (max-width: $desktop-min-width-minus) {
            font-size: rem-calc(24px);
          }
        }

        padding: 30px 36px;

        .track-font-size {
          font-size: rem-calc(21px);

          @media (max-width: $desktop-min-width-minus) {
            font-size: rem-calc(18px);
          }
        }

        .date {
          @media (max-width: $desktop-min-width-minus) {
            font-size: rem-calc(16px);
          }
        }

        .display-flex {
          display: flex;
          width: 100%;

          @media (max-width: $desktop-min-width-minus) {
            font-size: rem-calc(16px);
          }

          .border-right {
            width: 124px;
            border-right: 1px solid;
            height: 40px;
          }

          .left-margins {
            margin-left: 20px;

            @media (max-width: $phablet-min-width-minus) {
              max-width: 160px;
              width: 100%;
            }
          }
        }
      }

      button {
        display: none;
      }
    }
  }
}

.compatibility-help-modal {
  width: 100%;
  max-width: 720px;
  border: 1px solid $input-border;
  height: 457px;

  @media (min-width: 641px) and (max-width: 725.9px) {
    overflow: scroll;
  }

  .modal-header {
    .modal-heading {
      width: 100%;
      font-size: rem-calc(25px);
      font-family: $secondary-font-family;
      font-weight: 500;
      line-height: 33px;
    }
  }

  .modal-body {
    padding: 0 15px;

    @media (#{$larger-than-phablet}) {
      padding: 0 30px;
    }

    .modal-content {
      padding: 0.5em 1em;

      .row {
        padding: 0;
      }

      .description-content {
        margin-bottom: 22px;
        max-width: 540px;
        font-size: rem-calc(18px);
        font-family: $base-font-family;
        line-height: 21px;
      }

      .list-content {
        text-align: left;
        font-weight: bold;
        line-height: 30px;
        font-family: $base-font-family-bold;
        margin: 0;
        padding: 5px 15px;
        font-size: rem-calc(18px);

        li {
          ul {
            margin: 0;
            padding: 0;
            list-style: disc;
          }
        }
      }

      .button {
        width: 149.8px;
        padding: 10px;
        font-size: rem-calc(20px);
        font-family: $secondary-font-family;

        @media (min-width: 1025px) {
          margin-top: 2px;
          margin-left: 5px;
        }

        &.primary {
          background-color: $secondary-color;
        }
      }
    }
  }

  @media (max-width: $phablet-min-width) {
    max-width: 315.6px;
    height: auto;
    max-height: none;
    border: 1px solid $primary-color;

    .modal-header {
      .modal-heading {
        font-size: rem-calc(22px);
        line-height: 28px;
        margin: 15px auto 0;
        max-width: 164px;
      }

      .modal-close {
        right: 0;
        top: 1px;
      }
    }

    .modal-body {
      .modal-content {
        padding: 0 10px;

        .description-content {
          font-size: rem-calc(15px);
          line-height: 22px;
        }

        .list-content {
          padding: 5px;
          font-size: rem-calc(13px);

          li {
            span {
              font-size: rem-calc(13px);
              line-height: 22px;
              font-weight: bold;
            }
          }
        }

        .modal-actions {
          padding: 0 5px 20px;
        }

        .button {
          width: 145px;
          font-size: rem-calc(20px);
        }
      }
    }
  }
}

.usp-pop-up-modal {
  width: 100%;
  max-width: 700px;
  height: 450px;

  @media (max-width: 888.9px) {
    overflow: scroll;
  }

  @media (max-width: 729.9px) {
    width: 300px !important;
    height: 587px;
  }

  .modal-header {
    padding: 24px;
    position: relative;

    @media (max-width: $phablet-min-width-minus) {
      margin-bottom: 16px;
    }

    .modal-heading {
      font-size: rem-calc(32px);
      line-height: 40px;
      width: 100%;
      margin-top: 0;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(24px);
        line-height: 32px;
        max-width: 300px;
      }
    }

    .modal-close {
      background-color: white;
      color: #863399;
      top: 32px;
      right: 16px;

      @media (max-width: 419.9px) {
        top: 5px;
        right: 5px;
      }
    }
  }

  .modal-body {
    .modal-content {
      padding-top: 0;

      .row {
        padding: 0 34px;

        @media (max-width: 729.9px) {
          padding: 0;
        }
      }

      .flex-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
      }

      .pop-header {
        max-width: 300px;
        width: 100%;
        background-color: rgba(245, 133, 32, 0.05);
        padding: 24px 32px;
        border-radius: 5px;
        font-family: $base-font-family;
        font-size: rem-calc(17px);
        line-height: 1.18;

        img {
          margin-right: 5px;
        }

        @media (max-width: 729.9px) {
          width: 290px;
          height: 108px;
        }
      }

      .sub-padding {
        padding-left: 30px;
        text-align: left;
        margin-bottom: 0;
      }

      .modal-actions button {
        padding: 8px;
        width: 220px;
        height: 43px;
        font-family: $secondary-font-family;
        font-size: rem-calc(20px);

        @media (max-width: 1024.9px) {
          margin-left: 0px;
        }

        @media (max-width: 729.9px) {
          font-size: 18px;

          &.secondary {
            margin-top: 10px;
          }
        }
      }

      // &.secondary {
      //   margin-left: 16px;
      // }

      // &.primary {
      //   margin-left: 38px;
      // }
    }

    .modal-content>*:last-child {
      margin-bottom: 0;

      @media (min-width: 730px) {
        margin-left: 12px;
      }

      @media (max-width: 729.9px) {
        margin-left: 0px;
        margin-top: 10px;
      }
    }

    .modal-content>* {
      padding: 0.5em 1em;

      @media (max-width: 729.9px) {
        padding: 0;
      }
    }
  }
}

.usp-pop-up-modal2 {
  @include modal-layout;

  .pop-header {
    margin-bottom: 12px;
  }
  
  .pop-body {
    margin: 0 auto;
    padding-left: 22px;
    text-align: left;
    width: fit-content;
  }
}

.usp-pop-up-modal3 {
  width: 575px !important;
  max-width: 720px;
  height: 360px;

  @media (max-width: $phablet-min-width-minus) {
    overflow: scroll;
    width: 300px !important;
    height: 462px;
  }

  text-align: center;

  .modal-header {
    max-width: 421px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 16px;
    margin-top: 32px;

    @media (max-width: $phablet-min-width-minus) {
      margin-top: 24px;
    }

    .modal-heading {
      width: 100%;
      height: 100%;
      font-size: rem-calc(32px);
      font-weight: bold;
      line-height: 40px;
      text-align: center;
      color: #383838;
      font-family: $secondary-font-family-bold;

      @media (max-width: $phablet-min-width-minus) {
        max-width: 268px;
        font-size: rem-calc(24px);
        line-height: 32px;
      }
    }

    .modal-close {
      background-color: unset;
    }
  }

  .modal-body {
    overflow: hidden;
    max-height: unset;

    .modal-content {
      text-align: center;
      padding-top: 3px;

      .row {
        padding: 0;
      }

      .flex-container {
        display: flex;
        flex-direction: column;

        @media (max-width: $phablet-min-width-minus) {
          width: 272px;
          height: 197px;
        }
      }

      .pop-header1 {
        display: inline-block;
        padding-right: 0px;
        width: 100%;
        font-family: $base-font-family;
        font-size: 18px;
        line-height: 0;
        margin-bottom: 16px;

        @media (max-width: $phablet-min-width-minus) {
          width: 290px;
          margin: 0 auto;
          margin-bottom: 15px;
          font-size: 16px;
          line-height: 24px;
          max-width: 235px;
        }
      }

      .pop-header2 {
        display: inline-block;
        padding-right: 0px;
        width: 560px;
        font-family: $base-font-family;
        font-size: 18px;
        line-height: 28px;
        margin-top: 0px;
        margin-left: 14%;
        text-align: left;
        margin-bottom: 0;

        @media (max-width: $phablet-min-width-minus) {
          font-size: 16px;
          line-height: 24px;
          max-width: 235px;
          margin-left: 2%;
        }

        li {
          margin-bottom: 8px;
        }

        @media (max-width: $phablet-min-width-minus) {
          width: 290px;
        }
      }

      .modal-actions {
        @media (min-width: 730px) {
          margin-top: 0px;
        }
      }

      .modal-actions button {
        padding: 4px 10px;
        width: 100px;
        height: 32px;
        font-family: $secondary-font-family;
        font-size: rem-calc(20px);
      }
    }

    .modal-content>*:last-child {
      margin-bottom: 0;
    }
  }
}

.deEnroll-modal {
  width: 100%;
  max-width: 960px;
  height: 100%;

  .modal-header {
    padding: 45px 20px;
    padding-bottom: 0px;

    .modal-heading {
      width: 100%;
      margin-bottom: 18px;
      font-size: rem-calc(28px);
      font-weight: bold;
      line-height: 1.32;
      text-align: center;
      color: #383838;
      font-family: $secondary-font-family-bold;
    }

    .modal-close {
      background-color: unset;
    }
  }

  .modal-body {
    padding-bottom: 45px;

    .modal-content {
      max-width: 730px;
      padding: 0em 1em;

      .modal-actions {
        padding: 0;
        margin: 20px auto;

        button {
          margin-right: 0px;
          padding: 12px 20px;
        }
      }
    }
  }
}

.change-expired-plan-modal {
  font-size: rem-calc(22px);
  font-family: $secondary-font-family-bold;
  width: 100%;
  max-width: 960px;

  .modal-header {
    .modal-close {
      background-color: white;
    }
  }

  .modal-body {
    overflow-x: visible;
    overflow-y: visible;

    .modal-content {
      padding: 0;

      .twelve {
        width: 100%;

        p {
          font-size: rem-calc(22px);
          line-height: 30px;
        }

        .second {
          padding: 0;
        }
      }
    }

    .modal-content>* {
      padding: 0 60px;
    }
  }

  &.cancel {
    .modal-header {
      padding-top: 20px;
    }

    .modal-body {
      .modal-content>* {
        padding: 0 40px;
      }
    }

    .button {
      padding: 10px 20px;
    }

    @media (max-width: $phablet-min-width) {
      .modal-body {
        .modal-content>* {
          padding: 0 20px;
        }
      }

      .modal-actions {
        margin-bottom: 30px;
      }
    }
  }

  .button {
    background-color: $secondary-color;
    margin: 10px;
    width: 359.8px;
    padding: 10px 30px;

    &.primary {
      display: none;
    }
  }

  .modal-actions {
    justify-content: center;
    align-items: center;
    display: block;
    margin-bottom: 20px;
  }

  @media (max-width: $desktop-min-width) {
    .modal-body {
      .modal-actions {
        display: flex;
        flex-direction: column;

        .button {
          &.primary {
            order: 2;
          }
        }
      }
    }
  }

  @media (max-width: $tablet-min-width) {
    .modal-body {
      overflow-y: auto;
    }
  }

  @media (max-width: $phablet-min-width) {
    max-width: 345px;

    .modal-body {
      .modal-content>* {
        padding: 16px 15px 0;
      }

      .modal-content {
        .row {
          &:first-child {
            margin-bottom: 0px;
          }
        }

        .twelve {
          p {
            font-size: rem-calc(14px);
            line-height: 18px;
          }

          .first {
            padding: 0 14px;
          }

          .second {
            padding: 0px;
            margin-bottom: 0px;
          }
        }

        .modal-actions {
          padding: 0px;

          .button {
            width: 315px;
            margin: 8px 0;
            font-size: rem-calc(17px);
          }
        }
      }
    }
  }
}

.christmas-tc {
  width: 100%;
  max-width: 1224px;

  .modal-header {
    @media (min-width: $desktop-min-width) {
      padding: 1em 1em 0;
    }

    .modal-heading {
      min-width: max-content;
      object-fit: contain;
      text-align: center;
      font-weight: bold;
      font-size: rem-calc(41px);
      font-family: $secondary-font-family-bold;
      line-height: 1.22;

      @media (max-width: 834.9px) {
        line-height: 1.07;
        min-width: unset;
      }
    }

    .modal-close {
      background: white;
    }
  }

  .modal-body {
    width: 100%;
    max-width: 100%;
  }

  .modal-content {
    .row {
      font-size: rem-calc(18px);
      width: 100%;
      padding: 0.5em 4em;

      .christmas-pop {
        max-width: 1020px;
      }

      @media (max-width: 1024px) {
        padding: 0.5em 1em;
        font-size: rem-calc(14px);
      }

      .button {
        display: none;
      }
    }
  }
}

.successPhoneModal {
  width: 700px!important;
  max-width: 100%!important;
  border: 2px solid $input-border;
  padding: 24px!important;
  border: none;
  @media(max-width: $desktop-min-width-minus) {
    width: 600px!important;
  }
  @media(max-width: $phablet-min-width-minus) {
    min-width: 440px!important;
    max-width: 575px!important;
    width: 100%!important;
    padding: 16px!important;
  }

  @media(max-width: 479.9px) {
    width: 288px!important;
    min-width: 288px!important;
  }

   .modal-header {
    .modal-heading {
      text-align: center;
      width: 100%;
      padding: 8px 48px 20px 48px;
      margin: 0;
      @media(max-width: $phablet-min-width-minus) {
        padding: 8px 48px 16px 48px;
      }
    }
    .modal-close {
      top: -13px;
      right: -10px;
      @media(max-width: $phablet-min-width-minus) {
        top: 0;
        right: 0;
      }
    }
  }
  
  .modal-body {
    .modal-content {
      padding: 0!important;
      .row {
        padding: 0 24px;
        text-align: center;
        margin-bottom: 0;
        @media(max-width: $phablet-min-width-minus) {
          padding: 0 16px;
        }

        .success-message {
          text-align: center;

          .message {
            margin-bottom: 12px;
            @media(max-width: $phablet-min-width-minus) {
              margin-bottom: 8px;
            }
          }
        }
      }

      .modal-actions {
        margin: 24px auto 32px auto;
        text-align: center;
        padding: 0!important;
        @media(max-width: $phablet-min-width-minus) {
          margin: 20px auto 28px auto;
        }
        .button {
          font-size: rem-calc(18px);
          line-height: 18px;
          width: 214px;
          padding: 12px 32px;
        }
      }
      .note {
        padding: 0!important;
        .details{
          font-size: rem-calc(12px);
          line-height: 20px;
          margin-bottom: 16px;
          color: $black;
          font-family: $base-font-family;
          @media(max-width: $phablet-min-width-minus) {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

.unlockedModal {
  width: 700px!important;
  max-width: 100%!important;
  border: 2px solid $input-border;
  padding: 24px!important;
  border: none;
  @media(max-width: $desktop-min-width-minus) {
    width: 600px!important;
  }
  @media(max-width: $phablet-min-width-minus) {
    width: 575px!important;
    max-width: 100%!important;
    padding: 16px!important;
  }
  .modal-header {
    .modal-heading {
      text-align: center;
      width: 100%;
      padding: 8px 48px 20px 48px;
      margin: 0;
      @media(max-width: $phablet-min-width-minus) {
        padding: 8px 48px 16px 48px;
      }
    }
    .modal-close {
      top: -13px;
      right: -10px;
      @media(max-width: $phablet-min-width-minus) {
        top: 0;
        right: 0;
      }
    }
  }

  .modal-body {
    .modal-content {
      padding: 0!important;
      .row {
        padding: 0 24px;
        text-align: center;
        margin-bottom: 0;
        @media(max-width: $phablet-min-width-minus) {
          padding: 0 16px;
        }
        .question-section {
          .question {
            font-weight: bold;
            margin-bottom: 8px;
            @media(max-width: $phablet-min-width-minus) {
              margin-bottom: 4px;
            }
          }

          .answer {
            margin-bottom: 12px;
            @media(max-width: $phablet-min-width-minus) {
              margin-bottom: 8px;
            }
          }
        }
      }

      .modal-actions {
        margin: 12px auto 16px auto;
        text-align: center;
        padding: 0!important;
        @media(max-width: $phablet-min-width-minus) {
          margin: 12px auto 12px auto;
        }
        .button {
          font-size: rem-calc(18px);
          line-height: 18px;
          width: 117px;
          padding: 12px 32px;
          margin-bottom: 40px;
          @media(max-width: $phablet-min-width-minus) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

.voucher-info-modal {
  width: 100%;
  max-width: 822px;
  border: 1px solid $input-border;
  border-radius: 11px;

  .modal-header {
    .modal-close {
      background-color: transparent;
    }

    .modal-heading {
      font-size: rem-calc(38px);
      font-family: $secondary-font-family-bold;
      line-height: 50px;

      @media (max-width: 1025px) {
        font-size: rem-calc(31px);
        line-height: 10px;
        margin-top: 25px;
      }
    }
  }

  .button {
    background-color: $secondary-color;
    width: 145px;
    padding: 10px;
    font-size: rem-calc(20px);
    line-height: 27px;

    @media (max-width: 1025px) {
      margin-top: 130px;
    }
  }

  .row {
    padding: 10px;

    &.modal-actions {
      padding-bottom: 30px;
    }
  }

  .text-content-holder {
    font-size: rem-calc(22px);
    margin-bottom: 20px;
    line-height: 26px;
    font-family: $base-font-family;
    display: inline-block;
    min-height: max-content;

    @media (max-width: 1025px) {
      font-size: rem-calc(17px);
      line-height: 20px;
    }
  }

  .image-container {
    position: relative;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    min-height: max-content;

    .pointer-holder {
      position: absolute;
      height: 50%;
      background-color: $black;
      width: 1px;
      z-index: 2;
      top: 222px;
      left: 55%;

      @media (#{$larger-than-desktop}) {
        left: 355px;
      }
    }

    img {
      width: 182.4px;
      height: 263px;
      left: 0;
    }

    .image-pointer {
      font-size: rem-calc(17px);
      line-height: 22px;
      display: inline-block;
      max-width: 280px;
      position: absolute;
      top: 375px;
    }

    @media (#{$larger-than-desktop}) {
      justify-content: left;
      padding-left: 80px;

      .pointer-holder {
        height: 80%;
        transform: rotate(90deg);
        top: 100px;
        background-color: $input-border;
      }

      .image-pointer {
        max-width: 225px;
        top: 200px;
        right: 36px;
        font-size: rem-calc(15px);
      }
    }
  }
}

.iccid-info-modal {
  width: 100%;
  max-width: 720px;
  border: 1px solid $input-border;
  border-radius: 11px;

  .modal-header {
    .modal-heading {
      font-size: rem-calc(25px);
      font-family: $secondary-font-family-bold;
      line-height: 33px;
    }

    @media (max-width: 833.9px) {
      .modal-heading {
        font-size: rem-calc(31px);
        line-height: 41px;
      }

      .modal-close {
        background-color: transparent;
      }
    }
  }

  .button {
    background-color: $secondary-color;
    width: 149.8px;
    padding: 7px;
    font-size: rem-calc(20px);
    line-height: 27px;
    margin-top: -41px;

    @media (max-width: 833.9px) {
      width: 145px;
    }
  }

  .row {
    padding: 10px;

    &.modal-actions {
      margin-bottom: 30px;

      @media (max-width: 833.9px) {
        padding-top: 60px;
      }

      @media (max-width: 427px) {
        padding-top: 130px;
        margin-bottom: 50px;
      }
    }
  }

  .text-content-holder {
    margin-bottom: 25px;

    .text {
      font-size: rem-calc(18px);
      margin-bottom: 20px;
      line-height: 21px;
      font-family: $base-font-family;
      display: inline-block;
      max-width: 581px;
    }
  }

  .image-container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: max-content;
    justify-content: left;
    padding-left: 0px;

    .pointer-holder {
      position: absolute;
      height: 120%;
      background-color: $black;
      width: 1px;
      z-index: 2;
      left: 248px;
      top: -49px;
      color: black;
      font-weight: bold;

      @media (min-width: 426px) {
        transform: rotate(90deg);
        color: black;
        font-weight: bold;
      }
    }

    img {
      width: 221.7px;
      height: 138.7px;
      left: 0;
    }

    .image-pointer {
      font-size: 0.9rem;
      line-height: 22px;
      position: absolute;
      top: 27px;
      width: 208px;
      text-align: left;
      left: 346px;
      font-weight: bold;
      color: black;

      @media (min-width: 834px) {
        right: 0;
        font-weight: bold;
      }
    }

    .rect {
      background-color: #f2e8f4;
      width: 90.7px;
      height: 13.3px;
      position: absolute;
      left: 96.3px;
      text-align: left;
      font-size: rem-calc(7px);

      &.rect1 {
        top: 28.4px;
      }

      &.rect2 {
        top: 46.4px;
      }

      &.rect3 {
        top: 73px;
      }

      &.rect4 {
        top: 78px;
        display: none;
      }
    }

    .big-rect {
      background-color: #f2e8f4;
      height: 9.4px;
      width: 56.7px;
      position: absolute;
      left: 325px;
      font-size: rem-calc(7px);
      text-align: left;

      &.rect1 {
        top: 61px;
      }

      &.rect2 {
        top: 72px;
      }

      &.rect3 {
        top: 83px;
      }

      &.rect4 {
        display: none;
        top: 94px;
      }
    }

    @media (max-width: 833.9px) {
      margin-left: 40px;

      .image-pointer {
        top: 25px;
        width: 230px;
        font-size: 0.8em;
        text-align: center;
      }
    }

    @media (max-width: 825px) {
      .image-pointer {
        width: 188px;
        left: 246px;
        top: 25px;
      }

      .pointer-holder {
        top: -16px;
        height: 73%;
        left: 189px;
      }
    }

    @media (max-width: 689px) {
      .image-pointer {
        width: 151px;
        left: 246px;
        top: 25px;
      }

      .pointer-holder {
        top: -16px;
        height: 73%;
        left: 189px;
      }
    }

    @media (max-width: 640px) {
      margin-left: 0;
    }

    @media (max-width: 477px) {
      .image-pointer {
        width: 128px;
        left: 227px;
        top: 25px;
      }
    }

    @media (max-width: 426.9px) {
      justify-content: center;

      .image-pointer {
        width: 230px;
        left: 22px;
        top: 183px;
      }

      .pointer-holder {
        top: 52px;
        height: 97%;
        left: 180px;
      }

      .rect {
        left: 148px;
      }

      .big-rect {
        width: 113.3px;
        height: 71.7px;
        border: solid 0.5px #1a1818;
        left: 200px;
        font-size: rem-calc(10px);

        &.rect1 {
          top: 205px;
        }

        &.rect2 {
          top: 223px;
        }

        &.rect3 {
          top: 241px;
        }

        &.rect4 {
          display: none;
          top: 259px;
        }
      }
    }

    @media (max-width: 406px) {
      .rect {
        left: 140px;
      }
    }

    @media (max-width: 390px) {
      .rect {
        left: 129px;
      }
    }

    @media (max-width: 376px) {
      .rect {
        left: 129px;
      }

      .pointer-holder {
        left: 184px;
      }

      .image-pointer {
        top: 189px;
      }
    }

    @media (max-width: 360px) {
      .pointer-holder {
        left: 162px;
      }

      .image-pointer {
        top: 186px;
        left: 9px;
      }

      .button {
        margin-top: -64px;
      }

      .rect {
        left: 121px;
      }
    }

    @media (max-width: 326px) {
      .pointer-holder {
        left: 162px;
      }

      .image-pointer {
        top: 186px;
        left: 9px;
      }

      .button {
        margin-top: -64px;
      }

      .rect {
        left: 102px;
      }
    }
  }
}

.ebb-validation {
  max-width: 777px;
  width: 100%;

  .modal-header {
    .modal-heading {
      color: #d94a47;
      font-size: rem-calc(27px);
      font-family: $secondary-font-family-bold;
      line-height: 34px;
      width: 100%;
      padding: 0 1em;
    }

    .modal-close {
      background-color: #d94a47;
      color: white;
      top: -9px;
      right: 0;
    }
  }

  .modal-content {
    padding: 37px;
    display: flex;
    flex-direction: column;

    .modal-actions {
      margin: 0;
      padding: 0;

      .button {
        background-color: #d94a47;
        border-color: #d94a47;
        color: #fff;
        width: 120px;
        padding: 10px;
      }
    }
  }
}

.delivery {
  width: 100%;
  max-width: 600px;

  .modal-body {
    .modal-content>* {
      padding: 20px 35px;
    }

    .modal-content {
      padding: 20px;
      display: flex;
      flex-direction: column;

      .id-section {
        padding: 0;

        .id {
          padding: 0px;
          margin-bottom: 10px;
        }
      }

      .dates {
        padding: 0;
      }

      .details {
        padding: 0 10px;
      }

      .date-title {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        margin-top: 20px;
        padding: 0;

        img {
          margin-right: 10px;
        }

        .title {
          font-family: $secondary-font-family-bold;
          font-size: rem-calc(18px);
          margin-bottom: 0;
        }
      }

      .step {
        display: flex;
        justify-content: space-between;
        padding: 0;

        .time {
          font-family: $secondary-font-family-bold;
          font-size: rem-calc(16px);
        }

        .time-details {
          display: flex;
          flex-direction: column;
          font-size: rem-calc(16px);
          align-items: flex-start;
          max-width: 92px;

          .status {
            margin-bottom: 5px;
          }

          .location {
            font-size: rem-calc(12px);
          }
        }
      }

      .modal-actions {
        margin: 0;
        padding: 0;

        .button {
          background-color: $primary-color;
          border-color: $primary-color;
          color: #fff;
          width: 120px;
          padding: 10px;
        }
      }
    }
  }
}
.verify-issue-modal {
  padding: 24px;
  width: 600px!important;
  .modal-header {
    .modal-close {
      top: -7px;
      right: 0;
    }

    .modal-heading {
      padding: 0px 12px 0;
      margin: 0;
    }
  }
  .modal-body {
    .modal-content {
      padding: 20px 0 0;
      .row {
        padding: 0;
        .last {
          margin-top: 12px;
          margin-bottom: 24px;
        }
        &.modal-actions {
          display: flex;
          gap: 16px;
          align-items: center;
          justify-content: center;
          
        }
      }
    }
  }
  @media(max-width: $desktop-min-width-minus) {
    max-width: 600px!important;
    width: 100%!important;
    .modal-header {
      .modal-heading {
        max-width: 345px;
        margin: 0 auto;
      }
    }
  }
  @media(max-width: $phablet-min-width-minus) {
    padding: 16px;
    .modal-header {
      .modal-heading {
        max-width: 324px;
        padding: 0 24px 0;
      }
    }
    .modal-body {
      .modal-content {
        padding: 16px 0 0;
        .row {
          .last {
            margin-top: 8px;
            margin-bottom: 0px;
          }
          &.modal-actions {
             flex-direction: column;
             gap: 16px;
             .button {
              width: 193px;
             }
          }
        }
      }
    }
  }
}
.clean-cart-modal {
  max-width: 800px;
  width: 100%;

  .modal-header {
    .modal-close {
      background-color: $primary-color;
      color: white;
      top: 0;
      right: 0;
    }

    .modal-heading {
      font-size: rem-calc(24px);
      line-height: 28px;
      font-family: $secondary-font-family-bold;
      width: 100%;
      padding-top: 24px;
    }
  }

  .modal-content {
    padding: 0 1em 1.2em;
  }

  .modal-body {
    .twelve {
      font-size: rem-calc(18px);
      line-height: 28px;
      max-width: 520px;
      width: 100%;
    }

    .modal-actions {
      .button {
        // background-color: $secondary-color;
        width: 175px;
        font-size: rem-calc(20px);
        font-family: $base-font-family-bold;
        padding: 10px 20px;

        &.primary {
          background-color: $primary-color;
          margin-right: 15px;
        }
      }
    }
  }

  &.addon {
    .modal-body {
      .modal-actions {
        .button {
          width: auto;

          &.primary {
            background-color: $primary-color;
            margin-right: 15px;
          }
        }

        @media(max-width: 718px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;

          .button {
            width: 232px;

            &.primary {
              margin-right: 0px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &.change-payment {
    .modal-body {
      .modal-actions {
        .button {
          width: auto;

          &.primary {
            background-color: $primary-color;
            margin-right: 15px;
          }
        }

        @media(max-width: 718px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;

          .button {
            width: 232px;

            &.primary {
              margin-right: 0px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  &.port {
    .modal-body {
      .modal-content {
        .row {
          p {
            margin-bottom: 12px;
            @media(max-width: $phablet-min-width-minus) {
              margin-bottom: 8px;
            }
          }
        }
      }
      .modal-actions {
        margin-top: 0;
        .button {
          &.secondary {
            border: none;
          }
        }
      }
    }
  }
  @media (max-width: $phablet-min-width-minus) {
    .modal-header {
      padding: 10px 30px;

      .modal-heading {
        padding-top: 8px;
      }

      .modal-close {
        top: 10px;
      }
    }

    .modal-body {
      .twelve {
        font-size: rem-calc(17px);
        line-height: 20px;
      }

      .modal-actions {
        margin-top: 24px;

        .button {
          width: 145px;
          font-size: rem-calc(16px);
          margin-bottom: 15px;

          &.primary {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.impacted-modal {
  max-width: 800px;
  width: 100%;

  @media (max-width: 641px) {
    margin-top: 0;
  }

  @media (max-width: 355px) {
    width: 88%;
  }

  margin-top: 0;

  .modal-header {
    .modal-close {
      background-color: white;
    }

    .modal-heading {
      font-size: rem-calc(38px);
      font-family: $secondary-font-family-bold;
      line-height: 50px;
      width: 100%;

      @media (max-width: $desktop-min-width-minus) {
        font-size: rem-calc(24px);
        line-height: 36px;
        width: 88%;
      }
    }
  }

  .modal-content {
    padding: 0 1em 1.2em;

    @media (max-width: 641px) {
      overflow-y: visible;
      max-height: 300px;
      margin-top: 0;
      padding: 0 1em 0;

      &.row {
        margin-bottom: 0;
      }
    }

    margin: 20px;
  }

  .modal-body {
    .twelve {
      font-size: rem-calc(17px);
      line-height: 20px;
      max-width: 720px;
      width: 100%;
    }

    .not-working-link {
      color: $secondary-color;
      cursor: pointer;

      @media (max-width: 641px) {
        margin-bottom: 0;
      }
    }

    .modal-actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 641px) {
        padding-bottom: 0;
      }

      .button {
        background-color: $secondary-color;
        width: 350px;
        font-size: rem-calc(20px);
        font-family: $secondary-font-family;
        padding: 10px 20px;
        margin-bottom: 10px;
        margin-top: 0;

        &.primary {
          background-color: $primary-color;
        }
      }
    }
  }

  @media (max-width: 641px) {
    .modal-header {
      padding: 10px 30px;

      @media (max-width: 355px) {
        padding: 13px 10px;
      }

      .modal-heading {
        font-size: rem-calc(24px);
        line-height: 32px;
      }
    }

    .modal-body {
      .twelve {
        font-size: rem-calc(17px);
        line-height: 20px;
      }

      .modal-actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        .button {
          width: 300px;
          font-size: rem-calc(14px) !important;
          margin-bottom: 10px;
          margin-top: 0;
        }
      }
    }
  }
}

.successful-activation-modal {
  width: 486.6px !important;
  border: 1px solid $primary-color;
  min-height: 258px;

  .modal-header {
    padding: 10px 35px;

    .modal-close {
      background-color: white;
    }

    .modal-heading {
      text-align: center;
      margin: 25px 0 0;
      width: 100%;
      font-size: rem-calc(25px);
      line-height: 33px;
    }
  }

  .modal-body {
    .modal-content {
      padding: 10px;
    }

    .row {
      padding: 5px 5px 0;
      font-size: rem-calc(17px);
      line-height: 20px;
      width: 300px;
    }

    .modal-actions {
      .button {
        width: 134.8px;
        padding: 10px 0px;
        font-size: rem-calc(20px);

        &.primary {
          background-color: $secondary-color;
        }
      }
    }
  }

  @media (max-width: $phablet-min-width) {
    border: 1px solid $grey;
    width: 100% !important;
    max-width: 346px !important;
    min-height: 285px;

    .modal-header {
      padding: 15px 10px 0;

      .modal-heading {
        text-align: center;
        font-size: rem-calc(31px);
        line-height: 41px;
        width: 274px;
        margin: 20px auto 10px;
      }
    }

    .modal-content {
      padding: 0 20px;
    }

    .modal-body {
      .row {
        padding: 3px 5px;
        font-size: rem-calc(15px);
        line-height: 17px;
      }

      .modal-actions {
        text-align: center;
        margin-bottom: 20px;

        .button {
          width: 145px;
          padding: 8px 0px;
        }
      }
    }
  }
}

.change-plan-modal {
  padding: 24px;
  width: 100%;
  max-width: 960px;

  .modal-header {
    .modal-heading {
      margin: 0 0 20px;
    }

    .modal-close {
      background-color: $primary-color;
      color: white;
      top: 0;
    }
  }

  .modal-body {
    .modal-content {
      padding: 0;

      p {
        margin-bottom: 8px;
      }
    }

    .modal-content>* {
      padding: 0;
    }
  }

  &.cancel {
    .modal-header {
      padding-top: 20px;
    }

    .modal-body {
      .modal-content>* {
        padding: 0 40px;
      }
    }

    @media (max-width: $phablet-min-width) {
      .modal-body {
        .modal-content>* {
          padding: 0 20px;
        }
      }

      .modal-actions {
        margin-bottom: 30px;
      }
    }
  }

  .button {
    margin: 10px;
    width: 359.8px;
    padding: 10px 30px;

    &.primary {
      background-color: $primary-color;
    }
  }

  .modal-actions {
    justify-content: center;
    align-items: center;
    display: block;
    margin-bottom: 20px;
  }

  @media (max-width: $desktop-min-width) {
    .modal-body {
      .modal-actions {
        display: flex;
        flex-direction: column;

        .button {
          &.primary {
            order: 2;
          }
        }
      }
    }
  }

  @media (max-width: $tablet-min-width) {
    .modal-body {
      overflow-y: auto;
    }
  }

  @media (max-width: $phablet-min-width) {
    max-width: 345px;

    .modal-body {
      .modal-content>* {
        padding: 16px 15px 0;
      }

      .modal-content {
        .row {
          &:first-child {
            margin-bottom: 0px;
          }
        }

        .twelve {
          p {
            font-size: rem-calc(14px);
            line-height: 18px;
          }

          .first {
            padding: 0 14px;
          }

          .second {
            padding: 0px;
            margin-bottom: 0px;
          }
        }

        .modal-actions {
          padding: 0px;

          .button {
            width: 315px;
            margin: 8px 0;
          }
        }
      }
    }
  }
}

.confirm-esim {
  border: 1px solid #e2e2e2;
  max-width: 700px;
  width: 100%;
  border-radius: 11px;

  .modal-header {
    .modal-heading {
      display: flex;
      flex-direction: column;
      font-size: rem-calc(32px);
      line-height: 32px;
      width: 100%;
      padding: 0 10px;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(24px);
      }
    }

    .modal-close {
      background-color: transparent;
      color: $primary-color;
    }
  }

  .modal-body {
    margin-top: 0px;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .info {
        font-size: rem-calc(20px);
        line-height: 32px;
        margin-bottom: 8px;
      }

      .focus {
        font-size: rem-calc(22px);
        line-height: 32px;
        margin-top: 4px;
        font-weight: bold;
      }
    }

    .row {
      margin-bottom: 0;
    }

    .button {
      width: 148px;
      padding: 8px;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: rem-calc(20px);
      font-family: $secondary-font-family-bold;
    }

    @media (max-width: $phablet-min-width-minus) {
      .content {
        .info {
          font-size: rem-calc(16px);
          line-height: 24px;
          margin-bottom: 4px;
        }

        .focus {
          font-size: rem-calc(18px);
          line-height: 28px;
        }
      }

      .button {
        font-size: rem-calc(16px);
        width: 124px;
      }
    }
  }
}

.migration-confirmation {
  border: 1px solid #e2e2e2;
  max-width: 700px;
  width: 100%;
  border-radius: 11px;

  .modal-header {
    .modal-heading {
      font-size: rem-calc(32px);
      line-height: 32px;
      width: 100%;
      padding: 0 10px;
    }

    .modal-close {
      background-color: transparent;
      color: $primary-color;
    }

    @media (max-width: $phablet-min-width-minus) {
      .modal-heading {
        font-size: rem-calc(24px);
        line-height: 24px;
      }
    }
  }

  .modal-body {
    margin-top: 16px;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 24px;
      gap: 24px;

      .message {
        font-size: rem-calc(20px);
        line-height: 32px;
        font-weight: bold;
      }

      .info {
        font-size: rem-calc(16px);
        line-height: 24px;
        margin-bottom: 0;
        font-weight: bold;

        .light {
          font-weight: 400;
        }
      }
    }

    .action {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;
      padding: 0 16px 32px;

      .button {
        width: 272px;
        padding: 8px;
        margin-top: 32px;
        font-size: rem-calc(20px);
        font-family: $secondary-font-family-bold;
      }
    }

    @media (max-width: $phablet-min-width-minus) {
      margin-top: 0;

      .content {
        .message {
          font-size: rem-calc(18px);
          line-height: 24px;
        }

        .action {
          flex-direction: column;
          padding-bottom: 24px;

          .button {
            margin-top: 0;
            width: 227px;
            font-size: rem-calc(16px);
          }
        }
      }
    }
  }
}

.Sim-replacement-iccid-modal {
  max-width: 600px;
  padding: 0 16px;
  width: 100%;
  height: 100%;

  .modal-header {
    .modal-close {
      right: 0;
      top: 0;
    }

    .modal-heading {
      padding: 20px 20px 0;
      max-width: 440px;
      // @media (max-width: $phablet-min-width) {
      //   font-size: rem-calc(24px);
      //   line-height: 32px;
      // }
    }
  }

  .modal-body {
    .modal-content {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .primary-col {
        a {
          font-family: $base-font-family-bold;
        }
      }

      .validation-message {
        text-align: left;
      }

      .button {
        width: 107px;
        font-size: rem-calc(20px);
        padding: 8px;
        margin-top: 20px;
      }

      .primary-col {
        max-width: 426px;
        width: 100%;
        margin: 0 auto;
        text-align: left;
        margin-top: 5px;
        color: $primary-color;
        font-size: 16px;

        @media (max-width: $desktop-min-width-minus) {
          max-width: 385px;
        }
      }

      input {
        width: 100%;
        height: 45px;
        padding: 0 10px;
      }

      .label {
        font-size: rem-calc(15px);
        font-family: $base-font-family;
        margin-bottom: 15px;
        line-height: 17px;
        margin-top: 20px;
        text-align: left;
        padding: 0 10px;
      }
    }
  }
}

.swap-success {
  border: 1px solid #e2e2e2;
  max-width: 600;
  width: 100%;
  height: 100%;

  .modal-header {
    .success-image {
      margin-bottom: 16px;

      img {
        max-width: 35px;
        max-height: 35px;
      }
    }

    .header {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .modal-body {
    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 0 20px;

      .modal-actions {
        padding-top: 0;

        .button {
          width: 111px;
          font-family: $secondary-font-family-bold;
          padding: 8px;
        }
      }
    }
  }
}

.acp-exists {
  .modal-header {
    .modal-close {
      right: 0;
      top: 0;
    }

    .modal-heading {
      font-size: rem-calc(24px);
      line-height: 28px;
      font-family: $secondary-font-family-bold;
      text-align: center;
      padding: 12px 20px 0;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(20px);
        line-height: 24px;
      }
    }
  }

  .modal-body {
    .modal-content {
      padding-bottom: 24px;

      .row {
        margin-bottom: 16px;
      }
    }
  }
}

.referral-mdn {
  .modal-header {
    .modal-close {
      right: 0;
      top: 0;
    }

    .modal-heading {
      font-size: rem-calc(24px);
      line-height: 28px;
      font-family: $secondary-font-family-bold;
      text-align: center;
      padding: 12px 20px 0;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(20px);
        line-height: 24px;
      }
    }
  }

  .modal-body {
    .modal-content {
      padding-bottom: 24px;

      .row {
        margin-bottom: 16px;
      }
    }
  }
}
.change-unavailable {
  .modal-header {
    .modal-close {
     display: none;
    }

    .modal-heading {
      font-size: rem-calc(24px);
      line-height: 28px;
      font-family: $secondary-font-family-bold;
      text-align: center;
      padding: 12px 20px 0;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(20px);
        line-height: 24px;
      }
    }
  }

  .modal-body {
    .modal-content {
      padding-bottom: 24px;

      .row {
        margin-bottom: 16px;
        padding: 0;
      }
    }
  }
}

.confirm-swap-modal {
  border: 1px solid #e2e2e2;
  max-width: 600px;
  width: 100%;
  height: 100%;

  .modal-header {
    .modal-close {
      right: 0;
      top: 0;
    }

    .modal-heading {
      font-size: rem-calc(24px);
      line-height: 28px;
      font-family: $secondary-font-family-bold;
      text-align: center;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(20px);
        line-height: 24px;
      }
    }
  }

  .modal-body {
    .modal-content {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        margin-bottom: 5px;
      }

      .question {
        font-size: rem-calc(18px);
        font-family: $secondary-font-family;
        line-height: 28px;
        margin-bottom: 0;
      }

      .confirm {
        font-size: rem-calc(22px);
        font-family: $secondary-font-family;
        line-height: 28px;
      }

      .button {
        width: 134.8px;
        font-size: rem-calc(20px);
        padding: 11px;
        margin-top: 0px;
      }

      .details {
        font-size: rem-calc(13px);
        font-family: $base-font-family;
        line-height: 15px;
      }

      @media (max-width: 640px) {
        overflow: hidden;

        .question {
          font-size: rem-calc(18px);
          line-height: 20px;
        }

        .confirm {
          font-size: rem-calc(18px);
          line-height: 20px;
        }
      }

      @media (max-width: 425.9px) {
        b {
          display: block;
          margin-top: 5px;
        }

        .modal-actions {
          margin: 0;
        }
      }
    }
  }

  .modal-content>* {
    padding: 0.5em 2em;
  }
}

.success-swap-modal {
  border: 1px solid $primary-color;
  max-width: 486.6px;
  width: 100%;

  @media (min-width: 1025px) {
    height: 517.3px;
  }

  .modal-header {
    .modal-close {
      background-color: transparent;
    }

    .modal-heading {
      font-size: rem-calc(25px);
      line-height: 33px;
      font-family: $secondary-font-family-bold;
      font-weight: bold;
    }
  }

  .modal-content {
    .subHeader {
      font-size: rem-calc(17px);
      line-height: 25px;
      font-family: $base-font-family-bold;
      font-weight: bold;
    }

    .details {
      font-size: rem-calc(17px);
      line-height: 25px;
      font-family: $base-font-family;
    }

    .modal-actions {
      margin-top: 0;
    }
  }

  .modal-content>* {
    padding: 0.5em 1em;
  }
}

.send-receipt-email-modal {
  .modal-header {
    .modal-heading {
      font-size: rem-calc(24px);
      line-height: 28px;
      padding-top: 16px;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(20px);
        line-height: 24px;
      }
    }

    .modal-close {
      top: 0;
    }
  }

  .modal-content {
    padding: 0.2em 2em;

    @media (max-width: $phablet-min-width-minus) {
      padding: 0.2em 1em;
    }

    .row {
      padding: 0;
      margin-top: 24px;
      display: block;

      @media (min-width: 750px) {
        max-width: 320px;
      }

      .details {
        font-size: rem-calc(18px);
        line-height: 25px;
        font-family: $base-font-family-bold;
        max-width: 540px;
        text-align: center;
        margin-bottom: 24px;
      }
    }

    .modal-actions {
      margin: unset;
      padding: unset;

      .primary {
        background-color: $primary-color;
        color: white;
        padding: 10px 26px;
        margin-top: 0;
        margin-left: 12px;
        margin-bottom: 20px;
      }

      .secondary {
        margin-top: 2px;
        margin-bottom: 20px;
        padding: 10px 20px;
      }
    }
  }

  .modal-content>* {
    padding: 0em 4em;

    @media (max-width: 500px) {
      padding: 0em 2em;
    }
  }
}

.custom-modal-voucher-results {
  border: 1px solid $input-border;
  width: 100%;

  .modal-header {
    .modal-close {
      background-color: transparent;
    }

    .modal-heading {
      width: 100%;
      font-size: rem-calc(38px);
      font-family: $secondary-font-family-bold;
      line-height: 26px;
      margin-top: 20px;
    }
  }

  .modal-content {
    .message {
      font-size: rem-calc(22px);
      line-height: 26px;
      font-family: $base-font-family;
      color: $black;
      font-weight: normal;

      &.balance {
        font-weight: bold;
        color: $black;

        .text-color-alert {
          color: $black;
        }
      }
    }
  }

  .modal-content>* {
    display: block;
  }

  .modal-actions {
    margin: 0;

    .button {
      margin-left: auto;
      margin-right: auto;
      width: 145px;
      padding: 10px;
      font-family: $secondary-font-family;
    }
  }

  @media (#{$larger-than-desktop}) {
    max-width: 960px;
  }

  @media (max-width: 750px) {
    .modal-header {
      .modal-heading {
        line-height: 36px;
      }
    }
  }

  @media (max-width: 640px) {
    .modal-header {
      .modal-heading {
        font-size: rem-calc(31px);
        line-height: 41px;
        max-width: 186px;
      }

      .modal-close {
        background-color: #ccc;
      }
    }

    .modal-content {
      .row {
        padding: 0 30px;
      }

      .message {
        font-size: rem-calc(17px);
        line-height: 20px;
      }
    }
  }
}

.custom-modal-show-countries {
  @media (min-width: 1025px) {
    width: 750px;
  }

  @media (min-width: 640px) and (max-width: 1024.9px) {
    width: 550px;
  }

  @media (min-width: 450px) and (max-width: 639.9px) {
    width: 380px;
  }

  height: 100%;
  padding: 0;

  .modal-header {
    height: 70px;
    box-shadow: 0 4px 6px 0 #00000036;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-heading {
      margin: 0;
      font-size: rem-calc(25px);
      font-family: $header-font-family;
    }

    .modal-close {
      color: white;
      top: 10px;
      right: 10px;
    }
  }

  .modal-body {
    overflow-x: hidden;

    .modal-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      table {
        @media (min-width: 1025px) {
          width: 550px;
        }

        @media (min-width: 640px) and (max-width: 1024.9px) {
          width: 450px;
        }

        @media (min-width: 450px) and (max-width: 639.9px) {
          width: 320px;
        }

        thead {
          color: $primary-color;
          position: sticky;
          top: -1px;

          th {
            padding-left: 0;
            text-align: center;
            font-weight: 700;
          }
        }

        td {
          text-align: center;
          font-family: $secondary-font-family;
        }
      }

      button {
        display: none;
      }
    }
  }
}

.select-addon-modal {
  width: 100%;
  max-width: 715.7px;
  border: 1px solid $input-border;

  .modal-header {
    .modal-heading {
      font-size: rem-calc(24px);
      line-height: 28px;
      font-family: $secondary-font-family-bold;
      width: 100%;
      padding-top: 24px;
    }
  }

  .modal-content {
    .row {
      display: flex;
      margin: 0;
      justify-content: center;

      &:first-child {
        display: none;
      }
    }
  }

  // .button {
  //   background-color: $secondary-color;
  //   margin: 10px 8px;
  //   padding: 8px 10px;
  //   width: 216px;
  //   font-size: rem-calc(20px);
  //   font-family: $secondary-font-family;

  //   &.primary {
  //     background-color: $primary-color;
  //     order: 2;
  //   }
  // }

  @media (max-width: $phablet-min-width) {
    max-width: 346px;


    .modal-content {
      padding: 0 17px;

      .row {
        display: flex;
        flex-direction: column;
      }

      .button {
        margin: 10px auto 0;

        &.primary {
          order: 2;
        }
      }
    }
  }
}

.confirm-change-checkout-modal {
  width: 700px;
  width: 100%;

  .modal-heading {
    width: 100%;
    padding: 30px 40px;
    max-width: 100%;
    margin: 0 auto;
  }

  .modal-header {
    .modal-close {
      i {
        color: white;
      }
    }
  }

  .modal-content {
    padding: 0 1em 1.2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .row {
      &.modal-actions {
        display: flex;
        gap: 16px;
      }
    }
  }

  .twelve {
    font-size: rem-calc(18px);
    line-height: 24px;
    margin: 0;
    padding: 0;
    width: 100% !important;
  }

  @media (max-width: $tablet-min-width) {
    .row {
      &:first-child {
        padding: 0 4em;
      }

      .twelve {
        padding: 0;
      }
    }
  }

  @media (max-width: 641px) {
    max-width: 346px;
    padding: 0;

    .row {
      &:first-child {
        padding: 0 1em;
      }

      &.modal-actions {
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}
.acp-modal {
  width: 100%;
  max-width: 850px;

  .modal-heading {
    width: 100%;
  }

  .modal-body {
    .modal-content {
      padding: 0.8em 1em;

      .modal-actions {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.SIM-replacement-modal {
  border: 1px solid $primary-color;
  max-width: 486.6px;
  width: 100%;

  @media (min-width: 641px) {
    height: 314px;
    max-height: 50vh;
  }

  .modal-header {
    .modal-close {
      right: 0px;
      top: 0;
    }
  }

  .modal-content {
    padding: 1em 2em;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    .row {
      padding: 0;
    }

    .question {
      font-size: rem-calc(25px);
      font-family: $secondary-font-family;
      font-weight: 500;
      line-height: 33px;
      max-width: 338px;
    }

    .number {
      font-size: rem-calc(30px);
      font-family: $base-font-family-bold;
      font-weight: bold;
      line-height: 34px;
      margin-bottom: 10px;

      p {
        margin: 0;
      }
    }

    .modal-actions {
      padding: 0;

      &.left {
        margin-bottom: 10px;
      }

      .button {
        width: 134.8px;

        &.primary {
          margin-bottom: 10px;
        }
      }
    }

    @media (max-width: 1025px) {
      .modal-actions {
        .primary {
          margin-right: 0px;
        }
      }
    }

    @media (max-width: 640px) {
      padding: 1em 1em;

      .question {
        font-size: rem-calc(20px);
        line-height: 27px;
      }

      .number {
        font-size: rem-calc(25px);
        line-height: 27px;
      }

      .modal-actions {
        display: block;
        padding: 0;

        &.left {
          margin-right: 0;

          button {
            &.primary {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .modal-content>* {
    @media (max-width: 1025px) {
      padding: 0.5em 3em;
    }
  }
}

.archived-plan {
  border: 1px solid #e2e2e2;
  max-width: 600px;
  width: 100%;
  border-radius: 11px;

  @media (min-width: $desktop-min-width) {
    width: 700px!important;
    max-width: 700px!important;
  }

  .modal-header {
    .modal-heading {
      display: flex;
      font-size: rem-calc(32px);
      line-height: 32px;
      width: 100%;
      padding: 18px 10px 10px;
      justify-content: center;
      margin-top: 10px;
      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(24px);
      }
    }
    .modal-close {
      top: 0;
    }
  }

  .modal-body {
    padding-bottom: 24px;
    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .row {
        padding: 0px;
        p {
          margin-bottom: 5px;
          font-size: 18px;
        }
      }


      .button {
        max-width: 145px;
        width: 100%;
        padding: 8px;
        font-size: rem-calc(16px);
      }

      .modal-actions {
        margin: 0;
      }
    }
  }
}

.esim-replacement {
  border: 1px solid #e2e2e2;
  max-width: 578px;
  width: 100%;
  border-radius: 11px;

  @media (max-width: 370px) {
    width: 92%;
  }

  .modal-header {
    .modal-heading {
      padding: 10px;
    }

    .modal-close {
      right: 0;
      top: 0;
    }
  }

  .modal-body {
    margin-top: 32px;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        margin-bottom: 16px;
        text-align: center;
      }

      .re-captcha--resizing {
        min-height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;

        @media (max-width: 370px) {
          padding-left: 66px;
        }
      }
    }

    .button {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }
}

.confirm-referral-modal {
  max-width: 740px;
  width: 100%;

  .modal-header {
    .modal-heading {
      width: 100%;
      text-align: center;
      margin: 12px auto 0;
    }
  }

  .modal-close {
    display: none;
  }

  .modal-body {
    .modal-actions {
      .button {
        margin-right: 10px;
      }
    }
  }
}

.is-lte-modal {
  max-width: 440px;

  .modal-header {
    padding: 1em 1em 0;
  }

  .modal-heading {
    line-height: 37px;
    font-size: rem-calc(28px);
    text-align: left;
    margin: 12px 0 0;
  }

  .modal-close {
    background: none;
  }

  .modal-content {
    padding: 0;
    text-align: left;
  }

  .modal-content>* {
    padding: 1.5em 1em;
  }

  .lte-custom-input {
    display: flex;

    label {
      margin-left: 12px;
    }

    input[type="radio"] {
      -webkit-appearance: none;
      width: 24px;
      height: 21px;
      border: 1px solid darkgray;
      border-radius: 50%;
      outline: none;
      box-shadow: 0 0 1px 0px gray;
    }

    input[type="radio"]:hover {
      box-shadow: 0 0 1px 0px $secondary-color;
    }

    input[type="radio"]:before {
      content: "";
      display: block;
      width: 80%;
      height: 80%;
      margin: 14% auto;
      border-radius: 50%;
    }

    input[type="radio"]:checked:before {
      background: $secondary-color;
    }

    input:focus {
      outline: none;
    }
  }

  .button {
    margin-top: 24px;
  }
}

.mdn-list {
  .modal-header {
    .modal-heading {
      font-size: rem-calc(32px);
      line-height: 40px;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(24px);
        line-height: 32px;
      }
    }

    .modal-close {
      background-color: transparent;
    }
  }

  .modal-body {
    .modal-content {
      display: flex;
      flex-direction: column;
      padding-top: 5px;
      padding-bottom: 32px;

      p {
        font-size: rem-calc(20px);
        line-height: 32px;
        margin-bottom: 10px;

        @media (max-width: $phablet-min-width-minus) {
          font-size: rem-calc(16px);
          line-height: 24px;
        }
      }

      .modal-actions {
        margin: 0;

        button {
          width: 133px;
          padding: 8px;
          font-size: rem-calc(20px);
          font-weight: 700;
          margin: 0;
        }

        @media (max-width: $phablet-min-width-minus) {
          padding-bottom: 0;

          button {
            font-size: rem-calc(16px);
            width: 98px;
          }
        }
      }
    }
  }
}

.confirm-modal {
  .modal-close {
    right: 0;
    background: none;
  }

  &.delete-duplicate {
    width: 100%;

    .modal-header {
      .modal-heading {
        font-size: rem-calc(32px);
        line-height: 40px;
        max-width: 495px;

        @media (max-width: $phablet-min-width-minus) {
          font-size: rem-calc(24px);
          line-height: 32px;
        }
      }

      .modal-close {
        display: none;
      }
    }

    .modal-body {
      .modal-content {
        display: flex;
        flex-direction: column;
        padding-top: 10px;

        .modal-actions {
          margin: 0;

          button {
            width: 94px;
            padding: 8px;
            font-size: rem-calc(20px);
            font-weight: 700;

            @media (max-width: $phablet-min-width-minus) {
              font-size: rem-calc(16px);
              width: 88px;
            }
          }
        }
      }
    }
  }

  .modal-actions {
    @media (max-width: $phablet-min-width-minus) {
      display: flex !important;
      flex-direction: column;
      margin: 0;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }

    .button {
      width: 145px;
      padding: 8px;

      &.primary {
        margin-right: 10px;

        @media (max-width: $phablet-min-width-minus) {
          margin: 0;
        }
      }
    }
  }
}

.edit-payment-modal {
  width: 100%;

  @media (min-width: 1024px) {
    max-width: 870px;
  }

  .modal-header {
    width: 100%;
    text-align: left;
    padding: 1em 2em 0;
    padding-left: 13px;

    .modal-close {
      right: 0;
    }

    .modal-heading {
      width: 100%;
      text-align: left;
      font-size: rem-calc(22px);
      line-height: 28px;

      @media (max-width: 639.9px) {
        font-size: rem-calc(31px);
        text-align: center;
        line-height: 33px;
      }
    }
  }

  .modal-body {
    max-height: 65vh;

    .modal-content {
      text-align: left;
      padding: 1.2em 0em;

      .content {
        display: flex;
        padding: 13px 13px 0;
        text-align: left;

        .note {
          font-size: rem-calc(17px);
          line-height: 26px;

          .link {
            color: $primary-color;
            cursor: pointer;
          }
        }

        @media (max-width: 639.9px) {
          .note {
            font-weight: normal;
          }
        }
      }

      .form-section {
        display: inline-block;
        text-align: left;
        width: 100%;
        padding: 13px;

        .validation-message {
          display: table-cell;
          text-transform: inherit;
          padding: 10px 0;
        }

        .required-field {
          color: #d94a47;
        }

        .first-row {
          text-align: left;
          margin-bottom: 20px;
          width: 100%;

          .cardNumber-section {
            width: 55%;
            display: inline-block;

            .modal-label {
              font-weight: bold;
              font-size: rem-calc(15px);
              line-height: 17px;
            }
          }

          .name-section {
            width: 36%;
            display: inline-block;
          }
        }

        .second-row {
          text-align: left;

          .expiry-section {
            width: 55%;
            display: inline-block;

            .sperator {
              font-size: rem-calc(28px);
              position: relative;
              top: 5px;
              padding: 0 4px;
            }

            .select {
              width: 25%;
              margin-right: 0;

              select {
                height: 30px;
                width: 100%;
                padding: 4px 10px;
                font-size: rem-calc(13px);
              }

              .select-arrow {
                top: 12px;
              }
            }
          }

          .cvv-section {
            width: 36%;
            display: inline-block;

            input {
              max-width: 410px;
            }
          }
        }

        &.address-form {
          .title {
            font-family: $base-font-family-bold;
            font-size: rem-calc(15px);
            line-height: 17px;
            margin-bottom: 25px;
          }

          .populated {
            .address {
              font-size: rem-calc(17px);
              line-height: 22px;
              margin-bottom: 7px;

              .edit {
                padding-left: 10px;
                color: $primary-color;
                cursor: pointer;

                img {
                  background-color: $primary-color;
                  padding: 5px;
                  border-radius: 52%;
                }
              }
            }

            .responsive {
              display: none;
            }

            @media (max-width: 639.9px) {
              .address {
                .edit {
                  display: none;
                }
              }

              .responsive {
                display: block;
                color: $primary-color;
                cursor: pointer;
                padding: 8px 0;
                text-align: left;
                font-size: rem-calc(15px);

                img {
                  background-color: $primary-color;
                  padding: 5px;
                  border-radius: 52%;
                }
              }
            }
          }

          .addressForm {
            margin-bottom: 25px;

            input {
              padding: 5px 10px;
              max-width: 410px;
            }

            .addressName-section {
              display: inline-block;
              width: 100%;
              margin-bottom: 20px;
            }

            .address-section {
              width: 100%;
              display: inline-block;

              .ngui-auto-complete-wrapper {
                display: inline;

                input {
                  width: 250px;
                }
              }
            }

            .details-section {
              display: inline-block;
              width: 100%;
              margin-bottom: 20px;

              .state-section {
                display: inline-block;
              }

              .city-section {
                display: inline-block;
                padding-left: 5px;
              }
            }

            .postal-section {
              display: inline-block;
              width: 100%;
            }
          }
        }

        &.modal-actions {
          display: contents;

          .button {
            width: 220px;
            padding: 11px;
            font-size: 18px;
          }

          @media (max-width: 639.9px) {
            display: inline-block;
            margin: auto;
            text-align: center;
          }
        }

        @media (max-width: 1024.9px) {
          .first-row {
            .cardNumber-section {
              width: 100%;
              margin-bottom: 20px;
            }

            .name-section {
              width: 100%;

              input {
                width: 410px;
                max-width: 410px;
              }
            }
          }

          .second-row {
            .expiry-section {
              width: 100%;
              margin-bottom: 6px;

              label {
                padding-right: 27px;
              }

              .select {
                max-width: 192px;
                width: 100%;

                @media (min-width: 640px) and (max-width: 688.9px) {
                  width: 47%;
                }

                select {
                  max-width: 192px;
                  width: 100%;

                  @media (min-width: 640px) and (max-width: 688.9px) {
                    width: 100%;
                  }
                }
              }
            }

            .cvv-section {
              width: 100%;

              label {
                margin-right: 58px;
              }
            }
          }

          &.address-form {
            .addressForm {
              .addressName-section {
                width: 100%;
                margin-bottom: 20px;

                label {
                  margin-right: 46px;
                }

                input {
                  width: 410px;
                }
              }

              .address-section {
                width: 100%;

                .ngui-auto-complete-wrapper {
                  input {
                    width: 412px;
                  }
                }
              }

              .details-section {
                width: 100%;
                margin-bottom: 20px;

                .state-section {
                  label {
                    margin-right: 49px;
                  }

                  input {
                    width: 167px;
                  }
                }

                .city-section {
                  label {
                    margin-left: 18px;
                    margin-right: 5px;
                  }

                  input {
                    width: 167px;
                  }
                }
              }

              .postal-section {
                width: 100%;

                label {
                  margin-right: 25px;
                }
              }
            }
          }
        }

        @media (max-width: 833.9px) {
          label {
            display: block;
          }

          &.address-form {
            .addressForm {
              .details-section {
                .city-section {
                  label {
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }

        @media (max-width: 639.9px) {
          label {
            font-size: rem-calc(17px);
            line-height: 20px;
          }

          .first-row {
            .name-section {
              input {
                width: 100%;
                max-width: 410px;
              }
            }
          }

          .second-row {
            .expiry-section {
              .select {
                width: 45%;

                select {
                  width: 100%;
                }
              }
            }

            .cvv-section {
              input {
                width: 100%;
              }
            }
          }

          &.address-form {
            .addressForm {
              .addressName-section {
                input {
                  width: 100%;
                }
              }

              .address-section {
                .ngui-auto-complete-wrapper {
                  input {
                    width: 100%;
                  }
                }
              }

              .details-section {
                .city-section {
                  display: block;
                  padding-left: 0;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.shipping-address-modal {
  width: 100%;
  max-width: 960px;
  border: 1px solid $input-border;

  .modal-header {
    position: relative;
    background-color: #fff;
    padding: 1em 2em 0;
    text-align: left;
    font-weight: bold;

    @media(max-width: $phablet-min-width-minus) {
      padding: 1em 0em 0;
    }

    .modal-close {
      background-color: $primary-color;

      .icon-close::before {
        color: white;
      }
    }

    .modal-heading {
      font-family: $secondary-font-family-bold;
      font-weight: bold;
      width: 100%;
      text-align: left;
    }
  }

  .modal-body {
    overflow: unset;
  }

  .modal-content>* {
    padding: 0px 15px;
    display: flex;
  }

  .modal-content {
    text-align: left;

    @media (#{$larger-than-desktop}) {
      padding: 0px 100px 20px 20px;
    }

    .twelve {
      width: 100%;
      margin: 0;
      text-align: left;

      &.address-lookup {
        input {
          width: 66.66666666666666%;

          @media (max-width: 640px) {
            width: 100%;
          }
        }
      }
    }

    label {
      text-align: left;
    }

    input {
      width: 100%;
      height: 56px;
    }

    fieldset {
      margin-bottom: 16px;
    }

    i {
      display: none;
    }

    .radio {
      display: inline-flex;
    }

    .row {
      margin-bottom: 0;
    }

    .modal-actions {
      margin: 0 0 10px;
      padding: 0 15px 20px;

      .button {
        display: none;

        &.primary {
          display: inline-block;
          width: 220px;
          font-size: rem-calc(20px);
          padding: 10px;
        }
      }
    }
  }

  @media (#{$larger-than-desktop}) {
    width: 960px;
  }

  @media (#{$larger-than-phablet}) {
    .eight {
      width: 66.66666666666666%;
      padding: 10px;
    }

    .four {
      width: 33.33333333333333%;
      padding: 10px;
    }

    .twelve {
      padding: 10px;
    }
  }

  @media (max-width: $phablet-min-width) {
    .modal-header {
      .modal-heading {
        font-weight: bold;
        text-align: center;
      }
    }

    .modal-content {
      padding: 0.5em 0em;
    }

    .modal-body {
      padding: 10px 0 20px;

      label {
        font-size: rem-calc(17px);
        line-height: 20px;
        font-family: $base-font-family;
      }

      input {
        height: 40px;
      }

      .modal-actions {
        float: none;
        text-align: center;
        margin: 0 auto;

        .button {
          &.primary {
            width: 145px;
          }
        }
      }
    }
  }
}

.payment-method-modal {
  width: 100%;
  max-width: 960px;

  .modal-header {
    background-color: #fff;

    .modal-heading {
      text-align: left;
      width: 100%;
      margin: 12px;
      font-size: rem-calc(22px);
    }

    .modal-close {
      background-color: transparent;
    }
  }

  .modal-content {
    >* {
      display: flex;
      justify-content: flex-start;
      padding: 0;
    }

    .form-section {
      .credit-image {
        display: none;
      }
    }

    fieldset {
      text-align: left;
      margin-bottom: 15px;
    }

    h4 {
      text-align: left;
      margin-bottom: 10px;
    }

    label {
      text-align: left;
    }

    input {
      width: 100%;
      height: 56px;
      margin-bottom: 20px;
    }

    select {
      height: 30px;
      padding: 2px 10px;
    }

    .select-arrow {
      top: 12px;
    }

    i {
      display: none;
    }

    .radio {
      display: inline-flex;
    }

    .modal-actions {
      margin: 10px 0;

      .button {
        width: 213px;
        padding: 10px;
      }
    }

    @media (max-width: 1024px) {
      .row {
        margin: 10px 15px;

        .four {
          padding-right: 10px;
        }
      }

      .radio {
        margin-left: 15px;
      }
    }
  }
}

.christmas-tc {
  width: 100%;
  max-width: 100%;
}

.migration-flows-popup {
  height: 100%;
  background-color: white;

  @media (min-width: 700px) {
    width: 700px;
  }

  @media (max-width: 699.9px) {
    width: 600px;
  }

  @media (max-width: 610.9px) {
    width: 300px;
  }

  .modal-header {
    display: flex;
    justify-content: flex-end;
    padding: 1em 0;

    @media (max-width: 1199.9px) {
      padding: 1em 1em 0;
    }
  }

  .modal-heading {
    margin-top: 10px;
    text-align: left;
    margin-left: 0px;

    @media (max-width: 610.9px) {
      text-align: center;
      font-size: rem-calc(22px);
      margin-left: auto;
      line-height: 26px;
    }
  }

  .modal-close {
    background: none;
    border-radius: 50%;
  }

  .modal-body {
    overflow-x: hidden;

    .modal-content {
      padding: 0em 3.3em;

      @media (max-width: 1199.9px) {
        padding: 0em 2.3em;
      }
    }

    .swiper-container {
      width: 100%;
      max-width: 100%;
    }

    .swiper-pagination {
      text-align: left;

      @media (max-width: 610.9px) {
        text-align: center;
      }

      .swiper-pagination-bullet-active {
        background: $primary-color;
      }
    }

    .flex-display {
      width: 100%;
      display: flex;
      align-items: center;

      @media (max-width: 699.9px) {
        padding: 0 0em;
      }

      @media (max-width: 610.9px) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: 23px;
        padding: 0px;
      }

      .desc {
        max-width: 307px;
        width: 100%;
        text-align: left;
        font-size: 13px;
        line-height: 22px;

        @media (max-width: 610.9px) {
          text-align: center;
        }
      }

      .image {
        width: 273px;
        height: 245px;

        @media (max-width: 699.9px) {
          width: 249px;
          height: 223px;
        }

        @media (max-width: 610.9px) {
          margin-bottom: 30px;
        }
      }
    }

    .actions {
      display: flex;
      margin-bottom: 60px;
      align-items: center;

      @media (max-width: 610px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      button {
        width: 130px;
        font-size: rem-calc(15px);
        padding: 10px;

        &.last {
          width: 215px;
        }
      }

      .not-interested {
        color: $secondary-color;
        font-size: rem-calc(12px);
        margin-left: 20px;
        line-height: 15px;

        @media (max-width: 610px) {
          margin-left: 0px;
          margin-top: 18px;
        }
      }
    }
  }

  .modal-content>* {
    padding: 0em 0em;
    margin-bottom: 20px;
  }

  .modal-actions {
    button {
      position: relative;
      right: 200px;
      bottom: 50px;
      font-size: rem-calc(15px);

      @media (max-width: 699.9px) {
        right: 150px;
      }

      @media (max-width: 610.9px) {
        right: 0px;
        bottom: 10px;
      }
    }
  }
}

.pending-confirmation {
  width: 100%;
  max-width: 580px;

  .modal-header {
    padding: 24px 0;

    .modal-heading {
      font-size: rem-calc(24px);
      line-height: 32px;
      margin-bottom: 0;
      margin-top: 0;
    }

    .modal-close {
      background: transparent;
      color: $primary-color;
    }
  }

  .modal-body {
    .modal-content {
      padding: 0 32px 20px;

      .row {
        padding: 0;
      }

      .note {
        font-size: rem-calc(18px);
        line-height: 28px;
        margin-bottom: 5px;
        font-weight: 400;
      }

      .modal-actions {
        display: flex;
        justify-content: center;

        .button {
          padding: 8px;
          width: 170px;
          font-weight: bold;
          font-size: rem-calc(16px);
        }

        @media (max-width: $phablet-min-width-minus) {
          width: 100%;
          margin: auto;
          margin-bottom: 8px;
        }
      }
    }
  }
}

.invalid-card {
  width: 100%;
  max-width: 700px;

  .modal-header {
    padding: 32px 0;

    .modal-heading {
      font-size: rem-calc(32px);
    }

    .modal-close {
      background: transparent;
      color: $primary-color;
    }
  }

  &.switch {
    .modal-header {
      padding: 32px 0 0;
    }
  }

  .modal-body {
    .modal-content {
      .description {
        font-size: rem-calc(18px);
        line-height: 28px;
        margin-bottom: 16px;
        color: #262626;
      }

      .list {
        list-style-position: inside;
        padding-inline-start: 0;

        li {
          font-size: rem-calc(18px);
          line-height: 28px;
          margin-bottom: 8px;
          color: #262626;
        }
      }

      .note {
        margin-bottom: 4px;
        font-size: rem-calc(20px);
        line-height: 28px;
        font-weight: 400;
      }
    }
  }
}

.skip-tmo-modal {
  width: 100%;
  max-width: 700px;

  b {
    font-family: $base-font-family-bold;
  }
  .modal-header {
    padding: 24px 0 20px 0;
    @media (max-width: $phablet-min-width-minus) {
      padding: 16px 0;
    }
    .modal-heading {
      margin-bottom: 0;
      margin-top: 8px;
    }

    .modal-close {
      right: 0;
      top: 20px;
    }
  }

  .modal-body {
    .modal-content {
      padding: 0 32px 32px;

      @media (max-width: $phablet-min-width-minus) {
        padding: 0 0 32px;
      }

      .row {
        padding: 0;
        margin-bottom: 0;

        .tmo-skip-content {
          .desc {
            margin: 0 auto;
            margin-bottom: 24px;
            max-width: 604px;
            padding: 0 13%;
            @media (max-width: $phablet-min-width-minus) {
              margin-bottom: 20px;
            }
            .flex {
              display: inline-flex;
              align-items: center;
            }
            .tooltip {
              .tooltiptext {
                max-width: 388px!important;
                text-align: left;
                right: 0;
                left: -85px;
                &::after {
                  left: auto;
                  right: 86px;
                }
                @media(max-width: $phablet-min-width-minus) {
                  font-size: 14px;
                  line-height: 24px;
                  padding: 8px;
                }
                @media(max-width:562.9px) {
                  left: -158px;
                  &::after {
                    right: 20px;
                  }
                }
                @media(max-width:493.9px) {
                  width: 280px;
                  top: 33px;
                  left: -53px;
                  &::after {
                    right: 94px;
                  }
                }
                @media(max-width:384.9px) {
                  width: 259px;
                 left: -13px;
                }
                @media(max-width:329px) {
                  left: -53px;
                }
              }
            }
          }

          .actions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin-bottom: 16px;

            @media (max-width: $phablet-min-width-minus) {
              flex-direction: column;
            }

            button {
              font-weight: 700;
              min-width: 141px;
            }
          }

          .check-section {
            display: flex;
            align-items: center;
            justify-content: center;
            .not-sure {
             color: #383838;
            }
            .button {
              padding: 12px 8px;
              font-weight: bold;
              font-family: $base-font-family-bold;
            }
            @media (max-width: $phablet-min-width-minus) {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}

.information-message-modal {
  .modal-header {
    @media (min-width: $desktop-min-width) {
      padding: 1em 1em 0;
    }

    .modal-heading {
      @media (min-width: $desktop-min-width) {
        min-width: max-content;
      }
    }
  }

  .modal-content {
    .row {
      width: 100%;
    }
  }
}

.compatibility-help-modal-IME {
  width: 700px!important;
  max-width: 100%!important;
  border: 2px solid $input-border;
  padding: 24px!important;
  border: none;
  max-height: unset!important;
  @media(max-width: $desktop-min-width-minus) {
    width: 600px!important;
  }
  @media(max-width: $phablet-min-width-minus) {
    width: 575px!important;
    max-width: 100%!important;
    padding: 16px!important;
  }
  @media (min-width: 640px) and (max-width: 725.9px) {
    overflow: scroll;
  }

  .modal-header {
    .modal-heading {
      font-weight: bold;
      font-family: $secondary-font-family-bold;
      text-align: center;
      width: 100%;
      padding: 8px 48px 20px 48px;
      margin: 0;
      @media(max-width: $phablet-min-width-minus) {
        padding: 8px 48px 16px 48px;
      }
    }

    .modal-close {
      top: -10px;
      right: -10px;
      @media (max-width: $phablet-min-width-minus) {
        right: 0px;
        top: 0px;
      }
    }
  }

  .modal-body {
    padding: 0 24px;
    @media (max-width: $phablet-min-width-minus) {
      padding: 0 16px;
    }
    .modal-content {
      padding: 0!important;
      .row {
        padding: 0!important;
        margin-bottom: 0;
      }

      .description-content {
        max-width: 604px;
        font-family: $secondary-font-family;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        color: $black;
      }

      .intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 8px;
        margin-bottom: 12px;
        @media (max-width: $phablet-min-width-minus) {
          gap: 4px;
          margin-bottom: 8px;
        }
      }

      .step-section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-bottom: 8px;
        @media (max-width: $phablet-min-width-minus) {
          gap: 4px;
          margin-bottom: 4px;
        }
      }

      .menu-margins {
        text-align: center;
      }
      .modal-content-section {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        b {
          font-family: $secondary-font-family-bold;
        }
      }

      .or {
        margin-top: 12px;
        margin-bottom: 12px;
        font-family: $secondary-font-family-bold;
        font-weight: bold;
        @media (max-width: $phablet-min-width-minus) {
          margin-top: 8px;
          margin-bottom: 8px;
        }
      }

      .p-nowrap {
        margin-bottom: 8px;
        @media (max-width: $phablet-min-width-minus) {
          margin-bottom: 4px;
        }
        @media (min-width: 1025px) {
          white-space: nowrap;
        }
      }
      .modal-actions {
        margin: 0;
        padding: 0;
      }
      .button {
        margin: 16px 0 16px 0;
        @media (max-width: $phablet-min-width-minus) {
          margin: 16px 0 12px 0;
        }
        &.primary {
          background-color: $primary-color;
        }
      }
    }
  }
}

.skip-modal {
  width: 100%;
  max-width: 600px;

  .modal-header {
    padding: 15px 0;

    .modal-heading {
      line-height: 33px;
    }
  }

  .modal-body {
    .modal-content {
      padding: 15px 15px 30px;

      .row {
        padding: 0 20px;
      }

      .modal-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px;

        .primary {
          background-color: $primary-color;
          margin-right: 15px;
          color: white;

        }

        button {
          margin: 0;
          width: 190px;
          padding: 10px;
          @include button-theme($primary-color-hover);
          box-shadow: 0px 2px 2px 0px rgba(87, 35, 98, 0.40);
          border-color:$primary-color;
        }
      }

      @media (max-width: $phablet-min-width-minus) {
        .modal-actions {
          margin: 20px 0;
          display: flex;
          flex-direction: column;
          align-items: center;

          .primary {
            margin-right: 0;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.change-ebb-plan-modal {
  .modal-header {
    .modal-close {
      right: 0;
      top: 0;
    }

    .modal-heading {
      font-size: rem-calc(24px);
      line-height: 28px;
      font-family: $secondary-font-family-bold;
      text-align: center;
      padding: 12px 20px 0;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(20px);
        line-height: 24px;
      }
    }
  }

  .modal-body {
    .modal-content {
      @media (max-width: $phablet-min-width-minus) {
        padding: 0 16px;
      }
      .modal-actions {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: center;

        @media (max-width: $phablet-min-width-minus) {
          flex-direction: column;

        }
      }
    }
    .modal-content > * {
      padding: 12px 32px;
      @media (max-width: $phablet-min-width-minus) {
        padding: 0;
      }
    }
  }
}

.tyy-message-modal {
  width: 100% !important;

  .modal-header {
    padding: 1em 1em 0;

    .modal-heading {
      width: 64%;
      text-align: left;
      margin: 12px 0 0;
      line-height: 35px;
      font-size: rem-calc(24px);

      @media (max-width: $phablet-min-width-minus) {
        width: 75%;
        margin: 27px 0 0;
      }
    }
  }

  .modal-close {
    background: none;
  }

  .modal-content {
    width: 283px;
    text-align: left;
    padding: 1em 1em;

    .info {
      p {
        max-width: 285px;
        font-size: rem-calc(15px);
        font-family: $base-font-family;
      }

      .note {
        font-style: italic;
      }
    }
  }

  .modal-content>* {
    padding: 0;
    text-align: left;
  }

  .modal-actions {
    button {
      display: none;
    }
  }
}

.clear-cart-modal {
  .modal-header {
    .modal-close {
      background-color: $primary-color;
      color: white;
    }
  }

  .modal-body {
    .modal-content {
      display: flex;
      flex-direction: column;

      .modal-actions {
        .primary {
          margin-right: 15px;
          background-color: #863399;
        }

        button {
          width: 155px;
          padding: 10px;

        }

        @media (max-width: 424px) {
          button {
            width: 103px;
          }
        }
      }
    }
  }
}

.out-of-stock-modal {
  &.model-container {
    width: 100%;
    max-width: 800px;
  }

  .modal-heading {
    font-size: 40px;
    line-height: 48px;

    @media (max-width: $ipad-min-width-minus) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .modal-body {
    .modal-content {
      .row {
        font-size: 20px;
        line-height: 32px;
        max-width: 100%;
        padding: 0em 5em;

        @media (max-width: $ipad-min-width-minus) {
          padding: 0em 1em;
          font-size: 18px;
          line-height: 28px;
        }
      }

      .modal-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        @media (max-width: $ipad-min-width-minus) {
          flex-direction: column;
          margin: 0;
        }

        button {
          margin: 16px 0;
          margin-right: 16px;

          @media (max-width: $ipad-min-width-minus) {
            font-size: 16px;
            margin-right: 0px;
          }
        }

        a {
          font-size: 20px;
          line-height: 28px;

          @media (max-width: $ipad-min-width-minus) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.auto-renew-modal {
  .modal-header {
    .modal-heading {
      width: 100%;
      text-align: center;
      margin: 12px auto 0;
    }
  }

  .modal-close {
    display: none;
  }

  .modal-body {
    .modal-content {
      display: flex;
      flex-direction: column;

      .modal-actions {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: center;

        button {
          width: 155px;
        }

        @media (max-width: 600px) {
          flex-direction: column;

        }

      }
    }
  }
}

.auto-renew-modal2 {
  .modal-close {
    display: none;
  }

  .modal-body {
    .modal-content {
      .twelve {
        width: 100%;
        line-height: 2;

        @media (min-width: 1451px) {
          width: 91%;
        }
      }

      .modal-actions {
        .primary {
          font-size: 18px;
          background-color: #863399;
          margin-bottom: 12px;

          @media (max-width: 1060px) {
            display: table-row-group;
          }
        }

        button {
          width: 270px;

          &:nth-child(2) {
            font-size: 18px;

            @media (max-width: 365px) {
              margin-bottom: 25px;
            }
          }

          @media (max-width: 365px) {
            width: 250px;
          }
        }
      }
    }
  }
}

.auto-renewel-modal {
  .modal-close {
    display: none;
  }

  .modal-body {
    .modal-content {
      .modal-actions {
        .button {
          padding: 9px;
          margin-right: 15px;
          width: 235px;
          background-color: #f58520;

          &.primary {
            background-color: #863399;
          }
        }
      }
    }
  }
}

.promotion-terms-modal {
  &.model-container {
    width: 100%;
  }

  max-width: 745px;

  .modal-header {
    text-align: left;
    padding: 1em 2em 0;

    .modal-heading {
      text-align: left;
      color: #ff0505;
      width: 100%;
      font-family: $base-font-family;
      font-size: rem-calc(16px);
      line-height: 22px;
    }

    .modal-close {
      background-color: transparent;
    }
  }

  .modal-content>* {
    padding: 1em 2em;
  }

  .modal-content {
    text-align: left;
    font-size: rem-calc(13px);
    font-family: $base-font-family;

    .main-content {
      p {
        margin-bottom: 25px;
      }

      .first {
        margin-bottom: 0;

        .focus {
          font-weight: bold;
        }
      }

      .features-list {
        padding-inline-start: 15px;
        margin-top: 0;
      }

      .terms {
        margin-bottom: 0;

        .temsApplies {
          width: 46%;
          display: inline-block;
        }

        .action {
          width: 54%;
          display: inline-block;
          text-align: right;
        }

        @media (max-width: 712px) {
          .temsApplies {
            width: 100%;
            display: block;
          }

          .action {
            width: 100%;
            display: block;
            text-align: left;
            margin-top: 30px;
          }
        }
      }
    }

    .modal-actions {
      margin: 0 0 25px;
      float: right;
      padding: 1em 4em;

      .button {
        width: 213px;
        padding: 10px 5px;
        font-family: $secondary-font-family;
        font-size: rem-calc(17px);
      }

      @media (max-width: 712px) {
        margin: 0 auto;
        display: block;
        text-align: center;
        float: none;
      }
    }
  }
}

.ios-setup-modal {
  max-width: 800px;
  width: 100%;

  .modal-header {
    .modal-close {
      background-color: white;
    }

    .modal-heading {
      font-size: rem-calc(25px);
      font-family: $secondary-font-family-bold;
      line-height: 50px;
      text-align: left;
      width: 100%;
      max-width: 482px;
      height: 100%;

      @media (max-width: 640.9px) {
        width: 218px;
        height: 61px;
        line-height: 1.27;
        font-size: rem-calc(22px);
      }
    }
  }

  .modal-content {
    padding: 0 1em 1.2em;
  }

  .modal-body {
    .modal-content {
      @media (max-width: 640.9px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .row {
        @media (max-width: 640.9px) {
          padding: 0;
          margin-bottom: 0;
        }

        .flex-phone-display {
          margin-bottom: 32px;
        }
      }
    }

    .twelve {
      font-size: rem-calc(15px);
      line-height: 20px;
      max-width: 480px;
      width: 100%;
    }

    .modal-actions {
      padding: 0;

      .button {
        width: 175px;
        font-size: rem-calc(20px);
        font-family: $secondary-font-family;
        padding: 10px 20px;

        @media (max-width: 640.9px) {
          width: 145px;
          height: 43px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &.primary {
          background-color: $primary-color;
          margin: 0;
        }
      }
    }
  }
}

.ios-setup-modal2 {
  max-width: 800px;
  width: 100%;

  .modal-header {
    .modal-close {
      background-color: white;
    }

    .modal-heading {
      font-size: rem-calc(25px);
      font-family: $secondary-font-family-bold;
      line-height: 50px;
      text-align: left;
      width: 100%;
      max-width: 482px;
      height: 100%;

      @media (max-width: 640.9px) {
        width: 218px;
        height: 61px;
        line-height: 1.27;
        font-size: rem-calc(22px);
      }
    }
  }

  .modal-content {
    padding: 0 1em 1.2em;
  }

  .modal-body {
    .modal-content {
      @media (max-width: 640.9px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .row {
        @media (max-width: 640.9px) {
          padding: 0;
          margin-bottom: 0;
        }

        .flex-phone-display {
          margin-bottom: 32px;
        }

        &.modal-actions {
          padding: 0;

          &.display-block {
            @media (max-width: 640.9px) {
              display: block;
              order: 1;
            }
          }
        }
      }
    }

    .twelve {
      font-size: rem-calc(15px);
      line-height: 20px;
      max-width: 480px;
      width: 100%;
    }

    .modal-actions {
      padding: 0;

      .button {
        background-color: $primary-color;
        width: 175px;
        font-size: rem-calc(20px);
        font-family: $secondary-font-family;
        padding: 10px 20px;

        @media (max-width: 640.9px) {
          width: 145px;
          height: 43px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &.secondary {
          background-color: $secondary-color;
          margin-right: 87px;
          margin-left: 10px;

          @media (min-width: 641px) and (max-width: 1024.9px) {
            margin-right: 42px;
          }

          @media (max-width: 640.9px) {
            margin-left: 97px;
            margin-right: 95px;
            margin-top: -15px;
            margin-bottom: 32px;
          }
        }
      }
    }
  }
}

.five-toggle-modal {
  max-width: 700px;
  width: 100%;
  padding: 32px !important;
  position: relative;

  @media (max-width: $phablet-min-width-minus) {
    padding: 24px !important;
  }

  .modal-header {
    margin-bottom: 24px;
    padding: 0;
    position: unset;

    @media (max-width: $phablet-min-width-minus) {
      margin-bottom: 16px;
    }

    .modal-heading {
      font-size: rem-calc(32px);
      line-height: 40px;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(24px);
        line-height: 32px;
        max-width: 300px;
      }
    }

    .modal-close {
      background-color: white;
      color: #863399;
      top: 16px;
      right: 16px;
    }
  }

  .modal-body {
    .modal-content {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .row {
        padding: 0;
        width: 60%;
        margin: 0 auto;
        margin-bottom: 32px;
        font-size: rem-calc(18px);
        line-height: 28px;

        @media (max-width: 871.9px) {
          width: 100%;
        }

        @media (max-width: $phablet-min-width-minus) {
          font-size: rem-calc(16px);
          line-height: 24px;
          margin-bottom: 24px;
        }

        p {
          margin: 0;
        }

        .mdn {
          margin: 8px 0 0;
        }
      }

      .modal-actions {
        margin: 0;

        .button {
          margin: 0;
          background-color: $primary-color;
          padding: 8px 24px;
          font-size: 20px;
          line-height: 20px;
          font-weight: bold;

          @media (max-width: $phablet-min-width-minus) {
            font-size: 16px;
            line-height: 16px;
            padding: 8px 30px;
          }
        }
      }
    }
  }
}

.success-modal {
  width: 700px !important;
  padding: 32px !important;

  @media (max-width: $desktop-min-width-minus) {
    width: 575px !important;
  }

  @media (max-width: $phablet-min-width-minus) {
    width: 288px !important;
    padding: 24px 24px 28px !important;
  }

  .modal-header {
    margin-bottom: 32px;
    padding: 0;

    @media (max-width: $phablet-min-width-minus) {
      margin-bottom: 24px;
    }

    .success-image {
      img {
        width: 90px;
        height: 90px;

        @media (max-width: $phablet-min-width-minus) {
          width: 64px;
          height: 64px;
        }
      }
    }
  }

  .modal-body {
    .modal-content {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .row {
        padding: 0;
        margin: 0 auto;
        margin-bottom: 32px;

        .success-message {
          font-size: rem-calc(32px);
          line-height: 40px;
          font-family: $base-font-family-bold;
          margin: 0;

          @media (max-width: $phablet-min-width-minus) {
            font-size: rem-calc(24px);
            line-height: 32px;
          }
        }
      }

      .modal-actions {
        margin: 0;

        .button {
          margin: 0;
          background-color: $primary-color;
          padding: 8px 24px;
          font-size: 20px;
          line-height: 20px;
          font-weight: bold;

          @media (max-width: $phablet-min-width-minus) {
            font-size: 16px;
            line-height: 16px;
            padding: 8px 32px;
          }
        }
      }
    }
  }
}

.skip-auto {
  max-width: 700px;
  padding: 32px !important;

  .modal-header {
    margin-bottom: 16px;
    padding: 0;

    .modal-heading {
      font-size: rem-calc(32px);
      line-height: 40px;
      max-width: 500px;
      width: 100%;
      margin: 0 auto;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(24px);
        line-height: 32px;
        max-width: 300px;
      }
    }

    .modal-close {
      background-color: white;
      color: #863399;
      top: -14px;
      right: 0px;
    }
  }

  .modal-body {
    .modal-content {
      padding: 0px;

      .modal-actions {
        margin: 0;
        display: flex;
        gap: 16px;

        @media (max-width: 500px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .button {
          background-color: $secondary-color;
          font-family: $base-font-family-bold;
          padding: 10px 8px;
          width: 228px;

          &.primary {
            background-color: $primary-color;
          }
        }
      }
    }
  }
}

.checkout-login-modal {
  width: 700px !important;
  padding: 24px 24px 32px 24px !important;
  min-width: 100%;
  @media (max-width: 749.9px) {
    width: 100% !important;
  }

  @media (max-width: $phablet-min-width-minus) {
    padding: 16px 6px 28px 6px !important;
    width: 100% !important;
    max-width: 97% !important;
  }

  .modal-header {
    padding: 0;

    .modal-heading {
      width: 100%;
      margin: 0 auto;
      @media(max-width: $phablet-min-width-minus) {
        padding: 0 40px;
      }
    }

    .modal-close {
      background-color: $primary-color;
      color: white;
      top: -10px;
      right: 10px;
      height: 28px;
      width: 28px;
      @media(max-width: $phablet-min-width-minus) {
        top: 0;
      }
    }
  }

  .modal-body {
    @media screen and (max-width: $phablet-min-width-minus) {
    max-height: calc(90vh - 85px);
    }
    .modal-content {
      display: flex;
      flex-direction: column;
      padding: 0;
      align-items: center;
      &.signup {
        margin-top: 32px;
        @media (max-width: $phablet-min-width-minus) {
          margin-top: 20px;
        }
      }
      .desc {
        margin: 0;
        padding: 12px 12px 0;
        margin-bottom: 24px;
        font-weight: normal;
      }

      fieldset {
        &.form-section {
          display: flex;
          flex-direction: column;
          text-align: left;
        }
      }
      .login-form {
        max-width: 400px;
        padding: 0;
        width: 100%;

        @media (max-width: $phablet-min-width-minus) {
          max-width: none;
          padding: 0 14px!important;
        }
        .actions-section {
          margin-top: 32px;
          margin-bottom: 0;
        }

        label {
          font-size: rem-calc(18px);
          font-family: $base-font-family-bold;
        }
      }
      .actions-section {
        display: flex;
        justify-content: space-between;

        @media (max-width: $phablet-min-width-minus) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 24px;
        }
      }
    }
  }
}

.error-modal {
  width: 700px !important;
  padding: 32px !important;

  @media (max-width: $desktop-min-width-minus) {
    width: 575px;
  }

  @media (max-width: $phablet-min-width-minus) {
    width: 292px;
    max-width: 100%;
    padding: 24px !important;
  }

  .modal-header {
    padding: 0;

    .modal-close {
      background-color: white;
      color: #863399;
      top: 0px;
      right: 0px;
    }

    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  .modal-body {
    .modal-content {
      .message {
        padding: 0;
      }
    }
  }
}

.verify-address-modal {
  width: 700px !important;
  padding: 0!important;
  max-height: unset!important;

  @media (max-width: $desktop-min-width-minus) {
    width: 575px!important;
  }

  @media (max-width: $phablet-min-width-minus) {
    width: 292px!important;
    max-width: 100%;
  }

  .modal-header {
    padding: 0 1em;

    .header {
      margin-bottom: 0;
      padding: 32px !important;
      @media (max-width: $phablet-min-width-minus) {
        padding: 24px !important;
      }
    }

    .modal-close {
      background-color: $primary-color;
      color: white;
      top: 10px;
      right: 28px;
      @media (max-width: $phablet-min-width-minus) {
        right: 18px;
      }
    }
  }

  .modal-body {
    .modal-content {
      padding: 0;
      .message {
        padding: 0;
      }

      .flex-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        @media (max-width: $phablet-min-width-minus) {
          gap: 12px;
        }
        .address-section {
          background-color: rgba(245, 133, 32, 0.05);
          padding: 24px 32px;
          border-radius: 5px;
          display: flex;
          gap: 8px;
          max-width: 320px;

          .address {
            display: flex;
            flex-direction: column;
            gap: 8px;

            p {
              text-align: left;
              margin-bottom: 0;
            }
          }

          @media (max-width: $phablet-min-width-minus) {
            padding: 24px;
          }

          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      .modal-actions {
        display: flex;
        gap: 24px;
        margin: 24px auto;
        padding: 0 20px 0;
        justify-content: center;


        @media (max-width: $phablet-min-width-minus) {
          flex-direction: column;
          gap: 12px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .modal-content > * {
    padding: 0;
  }
}

.mytab-modal {
  max-width: 700px !important;
  width: 100%;
  padding: 32px !important;

  @media (max-width: $desktop-min-width-minus) {
    max-width: 575px;
  }

  @media (max-width: $phablet-min-width-minus) {
    max-width: 292px;
    padding: 24px !important;
  }

  .modal-header {
    padding: 0;

    .modal-close {
      top: 0px;
      right: 0px;
    }
  }

  .modal-body {
    .modal-content {
      display: flex;
      flex-direction: column;
      padding: 0;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: $phablet-min-width-minus) {
        align-items: baseline;
        justify-content: center;
      }

      h4 {
        margin-bottom: 16px;
      }

      .subheader {
        font-size: rem-calc(18px);
        line-height: 28px;
        margin: 0;

        @media (max-width: $phablet-min-width-minus) {
          font-size: rem-calc(16px);
          margin-bottom: 8px;
        }
      }

      .list {
        list-style: none;
        margin-block-start: 0;
        margin-bottom: 0;

        li {
          font-size: rem-calc(14px);
          line-height: 24px;
          margin-bottom: 12px;
          color: #383838;
          display: flex;
          align-items: flex-start;
          text-align: left;

          &::before {
            content: "\2022";
            color: $primary-color;
            font-weight: bold;
            display: inline-block;
            font-size: rem-calc(34px);
            width: 20px;
            margin-left: 0;
          }

          span {
            flex-basis: 95%;
          }

          .space {
            padding-left: 8px;
          }
        }

        @media (max-width: $phablet-min-width-minus) {
          margin-bottom: 15px;

          // padding-inline-start: 0;
          li {
            font-size: rem-calc(12px);
            margin-bottom: 4px;
          }
        }
      }

      .link {
        font-size: rem-calc(20px);
        line-height: 20px;
        margin-bottom: 24px;
        font-family: $secondary-font-family-bold;

        // padding: 0.5em 4em;
        @media (max-width: $phablet-min-width-minus) {
          font-size: rem-calc(16px);
          line-height: 16px;
          margin-top: 8px;
          padding-left: 40px;
          text-align: left;
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;

      .button {
        padding: 8px;
        font-size: 20px;
        width: 120px;
      }
    }
  }
}

.manage-enrollment-modal {
  max-width: 600px;
  width: 100%;
  padding: 32px !important;
  position: relative;

  @media (max-width: $phablet-min-width-minus) {
    padding: 24px !important;
  }

  .modal-header {
    margin-bottom: 0px;
    padding: 0;
    position: unset;

    .modal-heading {
      font-size: rem-calc(32px);
      line-height: 40px;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(24px);
        line-height: 32px;
        max-width: 300px;
      }
    }

    .modal-close {
      background-color: white;
      color: #863399;
      top: 16px;
      right: 16px;

      @media (max-width: 419.9px) {
        top: 5px;
        right: 5px;
      }
    }
  }

  .modal-body {
    .modal-content {
      display: flex;
      flex-direction: column;

      .row {
        padding: 0;
      }

      .modal-actions {
        padding: 0;

        .button {
          padding: 8px;
          font-family: $secondary-font-family-bold;
          width: 229px;
          font-size: 18px;

          @media (max-width: $phablet-min-width-minus) {
            width: 180px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.enable-5g-modal {
  max-width: 700px;
  width: 100%;
  padding: 32px !important;
  position: relative;

  @media (max-width: $phablet-min-width-minus) {
    padding: 24px !important;
  }

  .modal-header {
    margin-bottom: 24px;
    padding: 0;
    position: unset;

    @media (max-width: $phablet-min-width-minus) {
      margin-bottom: 16px;
    }

    .modal-heading {
      font-size: rem-calc(32px);
      line-height: 40px;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;

      @media (max-width: $phablet-min-width-minus) {
        font-size: rem-calc(24px);
        line-height: 32px;
        max-width: 300px;
      }
    }

    .modal-close {
      background-color: white;
      color: #863399;
      top: 16px;
      right: 16px;

      @media (max-width: 419.9px) {
        top: 5px;
        right: 5px;
      }
    }
  }

  .modal-body {
    .modal-content {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .row {
        padding: 0;
        width: 76%;
        margin: 0 auto;
        margin-bottom: 32px;
        font-size: rem-calc(18px);
        line-height: 28px;

        @media (max-width: 814.9px) {
          width: 70%;
        }

        @media (max-width: $phablet-min-width-minus) {
          font-size: rem-calc(16px);
          line-height: 24px;
          margin-bottom: 24px;
        }
      }

      .modal-actions {
        margin: 0;

        .button {
          margin: 0;
          background-color: $primary-color;
          padding: 8px 32px;
          font-size: 20px;
          line-height: 20px;
          font-weight: bold;

          @media (max-width: $phablet-min-width-minus) {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.timer-alert-modal {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  b {
    font-weight: bold;
    font-family: $base-font-family-bold;
  }

  img {
    width: 80px;
    height: 80px;
    margin-top: 32px;

    @media (max-width: $phablet-min-width-minus) {
      width: 60px;
      height: 60px;
    }
  }

  .modal-header {
    padding: 0;

    .modal-heading {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 24px;

      @media (max-width: $phablet-min-width-minus) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
      }
    }
  }

  .desc {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    font-weight: normal;

    &.last {
      margin-top: 16px;

      @media (max-width: $phablet-min-width-minus) {
        margin-top: 8px;
      }
    }

    @media (max-width: $phablet-min-width-minus) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .note {
    color: $secondary-color;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 24px;

    @media (max-width: $phablet-min-width-minus) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .modal-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    @media (max-width: $phablet-min-width-minus) {
      margin-bottom: 32px;
      flex-direction: column;
    }

    button {
      padding: 8px 32px;
      font-weight: bold;
      font-family: $secondary-font-family-bold;
      min-width: 211px;

      @media (max-width: $phablet-min-width-minus) {
        padding: 8px 24px;
      }
    }

    .left {
      margin-right: 16px;

      @media (max-width: $phablet-min-width-minus) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}

.futurePay-modal {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  b {
    font-weight: bold;
    font-family: $base-font-family-bold;
  }

  img {
    width: 80px;
    height: 80px;
    margin-top: 32px;

    @media (max-width: $phablet-min-width-minus) {
      width: 60px;
      height: 60px;
    }
  }

  .modal-header {
    padding: 0;

    .modal-heading {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 24px;

      @media (max-width: $phablet-min-width-minus) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
      }
    }
  }

  .desc {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    font-weight: normal;

    &.last {
      margin-top: 16px;

      @media (max-width: $phablet-min-width-minus) {
        margin-top: 8px;
      }
    }

    @media (max-width: $phablet-min-width-minus) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .note {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 24px;

    @media (max-width: $phablet-min-width-minus) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .modal-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    @media (max-width: $phablet-min-width-minus) {
      margin-bottom: 32px;
    }

    button {
      padding: 8px 32px;
      font-weight: bold;
      font-family: $secondary-font-family-bold;
      min-width: 211px;

      @media (max-width: $phablet-min-width-minus) {
        padding: 8px 24px;
      }
    }
  }
}

.migration-verfied-modal {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;

  b {
    font-weight: bold;
    font-family: $base-font-family-bold;
  }

  .modal-close {
    color: $primary-color;
    background: none;
    background-color: transparent;
    right: 3px;
  }

  .modal-header {
    padding: 0;
    padding-top: 32px;
    width: 100%;

    .modal-heading {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 16px;
      font-family: $secondary-font-family-bold;
      font-weight: bold;

      @media (max-width: $phablet-min-width-minus) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
      }
    }
  }

  .modal-body {
    padding: 0 4em;

    @media (max-width: $phablet-min-width-minus) {
      padding: 0 2em;
    }

    .title {
      font-size: 18px;
      line-height: 28px;
      font-weight: normal;
      margin-bottom: 4px;

      @media (max-width: $phablet-min-width-minus) {
        margin-bottom: 0;
      }
    }

    .activation-code {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      font-weight: normal;
      margin-bottom: 8px;
      font-family: $base-font-family-bold;
    }

    .info {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;

      @media (max-width: $phablet-min-width-minus) {
        margin-bottom: 20px;
      }
    }
  }

  .modal-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 40px;

    @media (max-width: $phablet-min-width-minus) {
      margin-bottom: 32px;
    }

    button {
      padding: 8px 32px;
      font-weight: bold;
      font-family: $secondary-font-family-bold;
      width: 137px;

      @media (max-width: $phablet-min-width-minus) {
        padding: 8px 24px;
        width: 116px;
      }
    }
  }
}

.already-purchased {
  @include modal-layout;

  .first {
    margin-bottom: 8px;

    @media (max-width: $phablet-min-width-minus) {
      margin-bottom: 4px;
    }
  }
}

.go-sim-modal {
  width: 700px!important;
  max-width: 100%!important;
  border: 2px solid $input-border;
  padding: 24px!important;
  border: none;
  @media(max-width: $desktop-min-width-minus) {
    width: 600px!important;
  }
  @media(max-width: $phablet-min-width-minus) {
    min-width: 440px!important;
    max-width: 575px!important;
    width: 100%!important;
    padding: 16px!important;
  }

  @media(max-width: 479.9px) {
    width: 288px!important;
    min-width: 288px!important;
  }
   .modal-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      text-align: center;
      width: 100%;
      padding: 8px 48px 20px 48px;
      margin: 0;
      @media(max-width: $phablet-min-width-minus) {
        padding: 8px 48px 16px 48px;
      }
    }
    .modal-close {
      top: -13px;
      right: -10px;
      @media(max-width: $phablet-min-width-minus) {
        top: 0;
        right: 0;
      }
    }
  }
  .sims-image {
    width: 72px;
    height: 72px;
    margin: 16px 0;
    @media(max-width: $phablet-min-width-minus) {
      width: 56px;
      height: 56px;
      margin: 12px 0 16px 0;
    }
  }
  .modal-body {
    .modal-content {
      padding: 0!important;
      .row {
        padding: 0 24px;
        text-align: center;
        margin-bottom: 0;
        @media(max-width: $phablet-min-width-minus) {
          padding: 0 16px;
        }

        .success-message {
          text-align: center;
          margin-bottom: 24px;
          @media(max-width: $phablet-min-width-minus) {
            margin-bottom: 20px;
          }
          b {
            font-weight: bold;
            font-family: $secondary-font-family-bold;
          }
        }
      }

      .modal-actions {
        margin-bottom: 16px;
        padding: 0!important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        @media(max-width: $phablet-min-width-minus) {
          margin-bottom: 12px;
        }
        .primary {
          font-size: rem-calc(18px);
          line-height: 18px;
          width: 214px;
          padding: 12px 32px;
          @media(max-width: $phablet-min-width-minus) {
            font-size: rem-calc(16px);
            line-height: 16px;
          }
        }
        .tertiary {
          font-size: rem-calc(16px);
          line-height: 16px;
          font-weight: bold;
          font-family: $base-font-family-bold;
          padding: 8px;
          @media(max-width: 479.9px) {
            padding: 8px 0;
          }
        }
      }
    }
  }
}

.remove-auto-pay-for-g2plan {
    width: 700px!important;
    max-width: 100%!important;
    border: 2px solid $input-border;
    padding: 24px!important;
    border: none;
    @media(max-width: $desktop-min-width-minus) {
      width: 600px!important;
    }
    @media(max-width: $phablet-min-width-minus) {
      min-width: 440px!important;
      max-width: 575px!important;
      width: 100%!important;
      padding: 16px!important;
    }
  
    @media(max-width: 479.9px) {
      width: 288px!important;
      min-width: 288px!important;
    }
     .modal-header {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .modal-heading {
        text-align: center;
        width: 100%;
        padding: 8px 48px 20px 48px;
        margin: 0;
        @media(max-width: $phablet-min-width-minus) {
          padding: 8px 32px 16px 32px;
        }
      }
      .modal-close {
        top: -13px;
        right: -10px;
        @media(max-width: $phablet-min-width-minus) {
          top: -5px;
          right: 0;
        }
      }
    }
    .modal-body {
      .modal-content {
        padding: 0!important;
        .row {
          padding: 0px;
          text-align: center;
          margin-bottom: 0;
          @media(max-width: $phablet-min-width-minus) {
            padding: 0 8px;
          }
        }
        .row::before, .row::after{
          display: none;
        }
        .modal-actions {
          margin: 24px 0 16px 0;
          padding: 0!important;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 16px;
          @media(max-width: $phablet-min-width-minus) {
            margin: 20px 0 12px 0;
          }
          .primary {
            font-size: rem-calc(18px);
            line-height: 18px;
            width: 213px;
            padding: 12px 24px;
            @media(max-width: $phablet-min-width-minus) {
              font-size: rem-calc(16px);
              line-height: 16px;
              width: 194px;
            }
          }
          .secondary {
            font-size: rem-calc(16px);
            line-height: 16px;
            font-weight: bold;
            font-family: $base-font-family-bold;
            padding: 8px;
            border: none;
            color: $primary-color;
            background: none;
            box-shadow: unset;
            @media(max-width: 479.9px) {
              padding: 8px 0;
            }
          }
          .secondary:hover {
            color: $primary-color-mid;
           
          }
          .secondary:focus {
            color: $tertiary-color;
          }
        }
      }
    }
}
.remove-auto-pay {
  @include modal-layout;
  .modal-heading { 
    max-width: 545px;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: $phablet-min-width-minus) {
      padding: 12px 32px!important;
    }
  }
  .row {
    padding: 0px 24px!important;
    @media(max-width: $phablet-min-width-minus) {
      padding: 0 8px!important;
    }
  }
  .modal-actions {
    button {
      font-size: rem-calc(18px);
      line-height: 18px;
      width: 93px;
      min-width: 93px;
      @media(max-width: $phablet-min-width-minus) {
       font-size: rem-calc(16px);
       line-height: 16px;
       width: 90px;
       min-width: 90px;
      }

    }
  }
}
.checkout-error-modal {
  @include modal-layout-600;
  .modal-heading { 
    max-width: 545px;
  }
  .modal-actions {
    .tertiary {
      font-size: rem-calc(16px)!important;
      line-height: 16px!important;;
      font-weight: bold;
      font-family: $base-font-family-bold;
      padding: 8px!important;
      @media(max-width: 479.9px) {
        padding: 8px 0!important;
      }
    }
  }
}
.change-cancel-g2plan {
  width: 700px!important;
  max-width: 100%!important;
  border: 2px solid $input-border;
  padding: 24px!important;
  border: none;
  @media(max-width: $desktop-min-width-minus) {
    width: 600px!important;
  }
  @media(max-width: $phablet-min-width-minus) {
    min-width: 440px!important;
    max-width: 575px!important;
    width: 100%!important;
    padding: 16px!important;
  }

  @media(max-width: 479.9px) {
    width: 288px!important;
    min-width: 288px!important;
  }
   .modal-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-heading {
      text-align: center;
      width: 100%;
      padding: 8px 48px 20px 48px;
      margin: 0;
      @media(max-width: $phablet-min-width-minus) {
        padding: 8px 48px 16px 48px;
      }
    }
    .modal-close {
      top: -13px;
      right: -10px;
      @media(max-width: $phablet-min-width-minus) {
        top: -5px;
        right: 0;
      }
    }
  }
  .modal-body {
    .modal-content {
      padding: 0!important;
      .row {
        padding: 0px 24px;
        text-align: center;
        margin-bottom: 0;
        @media(max-width: $phablet-min-width-minus) {
          padding: 0 16px;
        }
      }
      .row::before, .row::after{
        display: none;
      }
      .alert-msg {
        margin-top: 12px;
        color: #D22119;
        font-family: $base-font-family-bold!important;
        font-weight: bold;
        @media(max-width: $phablet-min-width-minus) {
          margin-top: 8px;
        }
      }
      .modal-actions {
        margin: 24px 0 16px 0;
        padding: 0!important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        @media(max-width: $phablet-min-width-minus) {
          margin: 20px 0 12px 0;
        }
        .primary {
          font-size: rem-calc(18px);
          line-height: 18px;
          width: 213px;
          padding: 12px 24px;
          @media(max-width: $phablet-min-width-minus) {
            font-size: rem-calc(16px);
            line-height: 16px;
            width: 194px;
          }
        }
        .secondary {
          font-size: rem-calc(16px);
          line-height: 16px;
          font-weight: bold;
          font-family: $base-font-family-bold;
          padding: 8px;
          border: none;
          color: $primary-color;
          background: none;
          box-shadow: unset;
          @media(max-width: 479.9px) {
            padding: 8px 0;
          }
        }
        .secondary:hover {
          color: $primary-color-mid;
         
        }
        .secondary:focus {
          color: $tertiary-color;
        }
      }
    }
  }
}

.remove-card-success {
  @include modal-layout;

  &.model-container {
    width: 600px;

    .modal-header {
      .modal-heading {
        padding-top: 8px;
      }
    }

    .number {
      margin-bottom: 4px;
    }

    .prev-cc {
      margin-top: 12px;

      @media (max-width) {
        margin-top: 8px;
      }
    }
  }
}

.add-activated-mdn {
  @include modal-layout;

  &.model-container {
    width: 600px;

    .modal-header {
      .modal-heading {
        padding-top: 8px;
      }
    }
    .success-image {
      margin-bottom: 16px!important;
    }
    .title {
        margin-bottom: 16px;
    }
    .subnote {
        margin-bottom: 8px;
    }
    .inner-content {
        padding: 0 16px;
    }
  }
}

.caution-auto-renewal {
  @include modal-layout;

  &.model-container {
    width: 600px;

    .modal-body .modal-content {
      padding: 0 24px;
      
      @media (max-width: $phablet-min-width) {
        padding: 0 16px;
      }
    }
  }
}

.claimed-g2plan {
    width: 700px!important;
    max-width: 100%!important;
    border: 2px solid $input-border;
    padding: 24px!important;
    border: none;
    @media(max-width: $desktop-min-width-minus) {
      width: 600px!important;
    }
    @media(max-width: $phablet-min-width-minus) {
      min-width: 440px!important;
      max-width: 575px!important;
      width: 100%!important;
      padding: 16px!important;
    }
  
    @media(max-width: 479.9px) {
      width: 288px!important;
      min-width: 288px!important;
    }
     .modal-header {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .modal-heading {
        text-align: center;
        width: 100%;
        padding: 8px 48px 20px 48px;
        margin: 0;
      }
      .modal-close {
        top: -13px;
        right: -10px;
        @media(max-width: $phablet-min-width-minus) {
          top: -5px;
          right: 0;
        }
      }
    }
    .modal-body {
      .modal-content {
        padding: 0!important;
        .row {
          padding: 0px;
          text-align: center;
          margin-bottom: 0;
          @media(max-width: $phablet-min-width-minus) {
            padding: 0 8px;
          }
        }
        .row::before, .row::after{
          display: none;
        }
        .modal-actions {
          margin: 24px 0 16px 0;
          padding: 0!important;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 16px;
          @media(max-width: $phablet-min-width-minus) {
            margin: 20px 0 12px 0;
          }
          .primary {
            font-size: rem-calc(18px);
            line-height: 18px;
            padding: 12px 24px;
            width: 107px;
            @media(max-width: $phablet-min-width-minus) {
              font-size: rem-calc(16px);
              line-height: 16px;
            }
          }
        }
      }
    }
}
.modal.is-visible,
.modal.fade.in {
  visibility: visible;
  transition: all 0.3s 0.12s;
  opacity: 1;
}

.modal.fade.show {
  visibility: visible;
  transition: all 0.3s 0.12s;
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.8);
  visibility: hidden;
  opacity: 0;
}

.modal-backdrop {
  opacity: 0;
  transition:
    visibility 0s linear 0.3s,
    opacity 0.3s;
}

.modal.is-visible .modal-overlay,
.modal-backdrop.fade.in {
  opacity: 1;
  visibility: visible;
}

.modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  transform: translateY(0);
  opacity: 1;
}