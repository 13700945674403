.activation-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.section-padding {
    padding-top: 32px;
    padding-bottom: 64px;
  }

  fieldset {
    margin: 0;
    width: fit-content;
  }

  a {
    font-family: $base-font-family-bolder;
    padding: 8px;
  }

  .agent-image {
    margin-bottom: 32px;
  }

  .header {
    text-align: center;

    &.top {
      margin-bottom: 4px;
    }
  }

  .body-content {
    margin-top: 32px;
  }

  .page-content,
  .body-content {
    max-width: 720px;
  }

  .desc {
    color: $grayscale-Shade-800;
    text-align: center;
    margin-top: 12px;
  }

  .page-actions {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    margin-top: 32px;
    width: 100%;
  }

  .button {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    justify-content: center;
    min-height: unset;
    min-width: unset;

    &.primary {
      img {
        transform: rotate(270deg);
      }
    }

    &.secondary,
    &.tertiary {
      img {
        transform: rotate(180deg);
      }
    }

    &.tertiary {
      padding: 8px;
    }
  }

  .validation-message {
    color: $light-red;
    font-family: $base-font-family;
    font-size: rem-calc(12px);
    font-weight: 400;
    margin: 4px 0 0 8px;
    line-height: 20px;
  }

  @media (max-width: $phablet-min-width-minus) {
    &.section-padding {
      padding-top: 24px;
      padding-bottom: 52px;
    }

    .agent-image {
      margin-bottom: 24px;

      img {
        height: 80px;
        width: 80px;
      }
    }

    .body-content {
      margin-top: 28px;
    }

    .desc {
      margin-top: 8px;
    }

    .page-actions {
      margin-top: 24px;
    }

    .button {
      img {
        height: 14px;
        width: 14px;
      }
    }
  }
}
